<script setup lang="ts">
import { BlockColor } from "@/types";

const { color, filled, isQuotaBlock } = defineProps<{
  color?: BlockColor;
  filled?: boolean;
  title?: string;
  isQuotaBlock?: boolean;
}>();

const classes = {
  "border-gray-300": !isQuotaBlock && !filled,
  "border-black": isQuotaBlock || filled,
  "border-dashed bg-transparent": !filled,
  [color?.toLowerCase() || ""]: filled,
};
</script>

<template>
  <button class="time-block" :class="classes" :title="title ?? ''"></button>
</template>

<style scoped>
.time-block {
  @apply m-1 h-4 w-4 border;
}

/* BLOCK COLORS */
.time-block.red {
  background-color: #ff3a24;
}

.time-block.orange {
  background-color: #e67e22;
}

.time-block.yellow {
  background-color: #f1c40f;
}

.time-block.green {
  background-color: #2ecc71;
}

.time-block.turquoise {
  background-color: #1abc9c;
}

.time-block.blue {
  background-color: #3498db;
}

.time-block.purple {
  background-color: #9b59b6;
}

.time-block.pink {
  background-color: #ff2470;
}

.time-block.lightgray {
  background-color: #ecf0f1;
}

.time-block.darkgray {
  background-color: #95a5a6;
}

.time-block.black {
  background-color: #34495e;
}

.time-block.white {
  background-color: #fff;
}

.time-block.new {
  background-color: transparent;
}
</style>

<script setup lang="ts">
import { computed } from "vue";
import { getLastNDays, getNSurroundingDays, DAY_ID_FORMAT } from "@/util";
import { useAppStore } from "@/stores/app";
import { storeToRefs } from "pinia";
import { useDayStore } from "@/stores/day";

const { activeDayId, activeDayJs } = storeToRefs(useAppStore());
const { currentDayJs, currentDayId } = storeToRefs(useDayStore());

const last7Days = computed(() => {
  if (currentDayId.value !== activeDayId.value) {
    return [
      ...getNSurroundingDays(6, currentDayJs.value, activeDayJs.value),
      activeDayJs.value,
    ];
  }
  return getLastNDays(7, currentDayJs.value).reverse();
});
const { setDayId } = useDayStore();
</script>

<template>
  <div class="col-span-2 mr-2 mt-2 flex justify-between text-xs">
    <button
      v-for="(d, i) in last7Days"
      :key="i"
      class="w-12 border px-2 py-1 text-center text-xs"
      :class="{
        'bg-primary font-semibold text-white': d.isSame(currentDayJs),
        'border-black font-semibold': d.isSame(activeDayJs),
        // 'bg-gray-50': !d.isSame(currentDayJs),
      }"
      @click="
        setDayId(d.format(DAY_ID_FORMAT));
        $emit('changed');
      "
    >
      {{ d.format("M/D") }}
      <br />
      <span class="text-[0.6rem]">
        {{ d.isSame(activeDayJs) ? "Today" : d.format("ddd") }}
      </span>
    </button>
  </div>
</template>

<style scoped></style>

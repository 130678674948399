<script setup lang="ts">
// TODO - THIS SHOULD BE ASTRO
import BaseBlock from "@/components/BaseBlock.vue";
import { BlockColor } from "@/types";

// TODO - MAKE THIS ROUTABLE
const categories = [
  "Concepts",
  "Blocks",
  "Categories",
  "Analytics",
  // "Settings",
  "Everything Else",
];
</script>

<template>
  <div>
    <h1 class="">Help</h1>
    <br />

    <div class="flex items-start gap-x-4">
      <div class="sticky top-12 w-1/3 border-r md:w-1/5">
        <ul>
          <li v-for="category in categories" :key="category">
            <a :href="`#${category.toLowerCase().replace(' ', '')}`">{{
              category
            }}</a>
          </li>
        </ul>
      </div>

      <!-- MOVE TO ASTRO -->
      <!-- TODO - FIX WIDTH ISSUES -->
      <!-- TODO - CHANGE THIS TO BE MORE TUTORIAL FOCUSED
        - CONCEPT AND THEN ADD/EDIT/REMOVE
      -->
      <div class="prose w-2/3 md:w-4/5">
        <section id="concepts">
          <h2 class="mt-0">Concepts</h2>
          <p>Talloc was created in response to a few main ideas:</p>
          <ul>
            <li>Time is your most valuable asset.</li>
            <li>What gets measured gets managed.</li>
            <li>
              Everyone has the same 24 hours in a day, but the most successful
              people in the world are simply the best investors.
            </li>
          </ul>
          <p>Talloc brings clarity to how you're investing your time</p>
          <p>
            Are you actually spending your time on the things that matter most?
            Are you improving yourself? Or are you binging Netflix and scrolling
            Instagram each night while complaining about how life isn't fair?
          </p>
          <p>The data does't lie!</p>
        </section>

        <section id="blocks">
          <h2>Blocks</h2>
          <p>
            Each block represents half an hour of activity, capturing a distinct
            segment of your day.
          </p>
          <div class="flex items-center justify-center text-center text-xl">
            <BaseBlock :color="BlockColor.BLUE" filled class="!h-6 !w-6" />
            = 30 minutes
          </div>
          <p>
            Assuming you sleep for 8 hours, each day consists of 32 blocks. Each
            day in Talloc starts at 6am and ends at 10pm. In the future, you
            will be able to personalize these times.
          </p>
          <div class="text-center"><BaseBlock v-for="i in Array(32)" /></div>
          <p>
            Blocks can be in one of three states: empty, quota, or completed.
          </p>
          <div class="mt-4 flex items-center justify-center gap-x-4">
            <div class="border-r py-1 pr-4">Key</div>

            <div class="flex items-center text-[0.6rem] uppercase">
              <div class="mr-1">Empty</div>
              <BaseBlock class="!h-3 !w-3" />
            </div>
            <div class="flex items-center text-[0.6rem] uppercase">
              <div class="mr-1">Quota</div>
              <BaseBlock class="!h-3 !w-3" isQuotaBlock />
            </div>
            <div class="flex items-center text-[0.6rem] uppercase">
              <div class="mr-1">Completed</div>
              <BaseBlock
                class="!h-3 !w-3"
                isQuotaBlock
                filled
                :color="BlockColor.WHITE"
              />
            </div>
          </div>
        </section>

        <section id="categories">
          <h2>Categories</h2>
          <p>
            Each block is assigned to a category, which represents a type of
            activity or task. Each category can be prioritized based on how
            important it is to you.
          </p>
          <p>
            For example, you might say it's absolutely critical to
            <BaseBlock :color="BlockColor.RED" filled /><span
              class="font-bold text-red-500"
              >Read</span
            >
            for an hour (2 blocks) everyday while it's a low priority to go
            through your
            <BaseBlock :color="BlockColor.DARKGRAY" filled /><span
              class="font-bold text-gray-500"
              >Inbox</span
            >
            for 30 minutes (1 block).
          </p>
        </section>

        <section id="analytics">
          <h2>Analytics</h2>
          <p>
            As you record how you're allocating your time, a number of different
            metrics are generated to give you insight into your overall
            performance.
          </p>
          <!-- TODO - ADD BREAKDOWNS -->
        </section>

        <!-- TODO - ENABLE ONCE WE HAVE SOMETHING TO PUT HERE -->
        <section id="settings" v-if="false">
          <h2>Settings</h2>
          <p>Fill me in</p>
        </section>

        <section id="everythingelse" class="pb-80">
          <h2>Everything Else</h2>

          <!-- TODO - ADD FAQ? -->

          <p class="">
            Not finding what you need? Please reach out to us at
            <a href="mailto:help@talloc.app" class="text-blue-500 underline">
              help@talloc.app</a
            >
          </p>
        </section>
      </div>
    </div>
  </div>
</template>
<style scoped>
section {
  @apply scroll-mt-10;
}

section h2 {
  @apply mb-2;
}
</style>

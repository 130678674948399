import {
  DocumentData,
  FirestoreDataConverter,
  PartialWithFieldValue,
  QueryDocumentSnapshot,
  SetOptions,
  SnapshotOptions,
  Timestamp,
  WithFieldValue,
} from "firebase/firestore";

export class Block implements FirestoreDataConverter<Block> {
  readonly id: string;
  readonly categoryId: string;
  readonly dayId: string;
  readonly created: Timestamp;

  // TODO - INTERFACE?
  constructor({
    id,
    categoryId,
    dayId,
    created,
  }: {
    id: string;
    categoryId: string;
    dayId: string;
    created: Timestamp;
  }) {
    this.id = id;
    this.categoryId = categoryId;
    this.dayId = dayId;
    this.created = created;
  }

  toFirestore(modelObject: WithFieldValue<Block>): DocumentData;
  toFirestore(
    modelObject: PartialWithFieldValue<Block>,
    options: SetOptions
  ): DocumentData;
  toFirestore(
    modelObject: unknown,
    options?: unknown
  ): import("@firebase/firestore").DocumentData {
    return Block.toFirestore(modelObject as Block);
  }

  fromFirestore(
    snapshot: QueryDocumentSnapshot<DocumentData>,
    options?: SnapshotOptions | undefined
  ): Block {
    return Block.fromFirestore(snapshot, options);
  }

  // TODO - ADD TYPE FOR FIRESTORE BLOCK REPRESENTATION
  static toFirestore({ id, categoryId, created }: Block): any {
    return {
      id,
      categoryId,
      created,
    };
  }

  // TODO - ADD TYPE FOR FIRESTORE BLOCK REPRESENTATION
  static fromFirestore(snapshot: any, options: any): any {
    // TODO - REMOVE?
    const data = { ...snapshot.data(), id: snapshot.id };
    return new Block(data);
  }
}

import { defineStore } from "pinia";
import {
  arrayRemove,
  arrayUnion,
  doc,
  DocumentData,
  DocumentReference,
  setDoc,
  Timestamp,
  updateDoc,
} from "firebase/firestore";
import { firestore } from "@/firebase";
import { useAuthStore } from "./auth";
import dayjs from "dayjs";
import { Day } from "@/objects/day";
import { Block } from "@/objects/block";
import { DayOfWeek } from "@/types";
import { DAY_ID_FORMAT, getDayId, getLastNDays } from "@/util";
import { useCategoryStore } from "@/stores/category";
import { sendEvent } from "@/analytics";
import { useUserStore } from "./user";
import { useAppStore } from "./app";

export const useDayStore = defineStore("day", {
  state: (): any => {
    return {
      userDays: [],
      demoDays: Day.generateDemoData(),
      dayId: getDayId(),
    };
  },
  // TODO - USE THIS EVERYWHERE WITH CONVERTERS
  getters: {
    days: (state) => {
      return useAppStore().isDemo ? state.demoDays : state.userDays;
    },
    currentDayRef(): DocumentReference<DocumentData> {
      const userId = useAuthStore().uid;
      return doc(firestore, `users/${userId}/days/${this.currentDayId}`);
    },
    currentDayId(): string {
      return this.dayId;
    },
    currentDayJs(): dayjs.Dayjs {
      return dayjs(this.currentDayId, DAY_ID_FORMAT);
    },
    currentDayOfWeek(): string {
      const day = dayjs().format("dddd").toUpperCase();
      return DayOfWeek[day as keyof typeof DayOfWeek];
    },
    getCategoryBlocks:
      (state) =>
      (categoryId: string, dayId?: string): Block[] => {
        const id = dayId || state.currentDayId;
        // TODO - PARAMETERIZE THIS
        const day = state.days.find((d: Day) => d.id === id);
        return day?.blocks?.filter((b: Block) => b.categoryId === categoryId);
      },
    getDayBlocks:
      (state) =>
      (dayId: string = getDayId()): Block[] => {
        const day = state.days.find((d: Day) => d.id === dayId);
        return day?.blocks || [];
      },
    getDayRef: (state) => (dayId: string) => {
      const userId = useAuthStore().uid;
      return doc(firestore, `users/${userId}/days/${dayId}`);
    },
    getCategoryWeekBlockCounts:
      (state) =>
      (categoryId: string): any[] => {
        const days = getLastNDays(7);
        const { quotas } = useCategoryStore().getCategory(categoryId);
        return days
          .map((d) => {
            const dayId = getDayId(d);
            const day = state.days.find((d: Day) => d.id === dayId);
            return {
              id: dayId,
              date: d,
              count: day?.getCategoryBlocks(categoryId).length || 0,
              quota: quotas[d.format("dddd").toUpperCase()] || 0,
            };
          })
          .reverse();
      },
  },
  actions: {
    setDayId(dayId: string) {
      this.dayId = dayId;
    },
    setDays(days: Day[]) {
      this.userDays = days || [];
    },
    async createBlock(categoryId: string) {
      useCategoryStore().setActiveCategoryId(categoryId);

      try {
        const id = Math.random().toString(36).substring(2, 15);
        const payload = { id, categoryId, created: new Date() };

        if (useAppStore().isDemo) {
          // TODO - FIX - SO GROSS
          payload.created = Timestamp.fromDate(new Date()) as unknown as Date;
          this.demoDays[0].blocks.push(payload);
          sendEvent("demo-block-create");
          return console.log("DEMO MODE - SAVING BLOCK TO LOCAL STATE");
        }

        await setDoc(
          this.currentDayRef,
          {
            blocks: arrayUnion(payload),
            _updated: new Date(),
            _processed: null,
            // SET ID OF CONFIG USED?
          },
          { merge: true }
        );
        sendEvent("block-create");

        // TODO - MOVE DETECTION TO SOMEWHERE CLEANER
        if (!useUserStore().hasFirstBlock) {
          useUserStore().setUserProperty("milestones.firstBlock", new Date());
          sendEvent("milestone-first-block");
        }
      } catch (e) {
        // TODO - ERROR COMPONENT
        console.error("There was a problem creating this block... ", e);
      }
    },
    async removeBlock(block: Block) {
      useCategoryStore().setActiveCategoryId(block.categoryId);

      if (useAppStore().isDemo) {
        // TODO - FIX - SO GROSS
        this.demoDays[0].blocks = this.days[0].blocks.filter(
          (b: Block) => b.id !== block.id
        );
        sendEvent("demo-block-delete");
        return console.log("DEMO MODE - REMOVING BLOCK FROM LOCAL STATE");
      }

      try {
        await updateDoc(this.getDayRef(block.dayId), {
          blocks: arrayRemove(Block.toFirestore(block)),
          _updated: new Date(),
          _processed: null,
        });
        sendEvent("block-delete");
      } catch (e) {
        // TODO - ERROR COMPONENT
        console.error("There was a problem removing this block... ", e);
      }
    },
  },
});

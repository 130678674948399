import { createApp } from "vue";
import * as Sentry from "@sentry/vue";
import { CaptureConsole as CaptureConsoleIntegration } from "@sentry/integrations";
import { createPinia } from "pinia";

import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import calendar from "dayjs/plugin/calendar";
import localizedFormat from "dayjs/plugin/localizedFormat";
import customParseFormat from "dayjs/plugin/customParseFormat";
import minMax from "dayjs/plugin/minMax";
dayjs.extend(relativeTime);
dayjs.extend(calendar);
dayjs.extend(localizedFormat);
dayjs.extend(customParseFormat);
dayjs.extend(minMax);

import App from "@/App.vue";
import Icon from "@/components/Icon.vue";
import "@/assets/style.css";
import "@/firebase";
import router from "@/router";
import { useAuthStore } from "@/stores/auth";
import { useAppStore } from "./stores/app";

const app = createApp(App);
app.component("Icon", Icon);
app.use(createPinia());
app.use(router);

declare const __COMMIT_HASH__: string;
declare global {
  interface Console {
    green: (...args: any[]) => void;
  }
}

// START THE GLOBAL APPLICATION CLOCK
useAppStore().initialize();

// WTF IS THIS DOING?
// TODO - DO WE NEED TO KEEP THIS?
useAuthStore().isInitialized();

if (window.location.hostname !== "localhost") {
  Sentry.init({
    app,
    dsn: "https://975b9febdf15430982cad7c93a7efe6e@o68807.ingest.sentry.io/4504857740574720",
    logErrors: true,
    integrations: [
      new CaptureConsoleIntegration({
        levels: ["error"],
      }),
    ],
    release: __COMMIT_HASH__,
  });
}
console.green = (...args: any[]) => {
  // TODO - ADD SUPPORT FOR DEV ENVIRONMENT?
  window.location.hostname === "localhost" &&
    console.log(
      "%c%s",
      "background: #222; color: #00ff00; border: 1px solid #00ff00; padding: 2px;",
      ...args
    );
};

app.mount("#app");

import { defineStore, storeToRefs } from "pinia";
import { Timestamp } from "firebase/firestore";
import { useAppStore } from "./app";

import { useCategoryStore } from "./category";
import { useDayStore } from "./day";
import dayjs from "dayjs";
import { Category } from "@/objects/category";
import {
  getBlocksElapsed,
  getNextPeriod,
  toPercentageString,
  toSignedString,
} from "@/util";
import { Block } from "@/objects/block";
import { Analytics } from "@/objects/analytics";
import { Period, PeriodDays, PeriodShortLabels } from "@/types";

export const useAnalyticsStore = defineStore("analytics", {
  state: (): any => {
    return {
      engine: new Analytics(),
    };
  },
  getters: {
    timeSinceLastBlock: (state) => {
      const blocks = useDayStore().getDayBlocks();
      if (!blocks.length) return undefined;

      const { time } = useAppStore();
      const ts: Timestamp = blocks.at(-1).created;

      // MAKES SURE WE'RE NOT IN THE FUTURE - "In a few seconds"
      const min = dayjs.min(dayjs(ts.toDate()), time);
      return dayjs(min).from(time);
    },
    blocksToday: (state): number => {
      const blocks = useDayStore().getDayBlocks();
      return blocks.length;
    },
    quotaBlocksToday: (state) => {
      // const blocks = useDayStore().getDayBlocks();
      const categories = useCategoryStore().categories;
      const quota = categories.reduce((acc: number, c: Category) => {
        return acc + c.getQuota();
      }, 0);
      return quota;
    },
    quotaBlocksCompleted: (state) => {
      const blocks = useDayStore().getDayBlocks();
      const categories = useCategoryStore().categories;
      const completed = categories.reduce((acc: number, c: Category) => {
        const blocksCompleted = blocks.filter(
          (b: Block) => b.categoryId === c.id
        ).length;
        return acc + Math.min(blocksCompleted, c.getQuota());
      }, 0);
      return completed;
    },
    runningEfficiency: ({ blocksToday }: { blocksToday: number }) => {
      const { time } = useAppStore();
      // TODO - FIGURE OUT DESIRED BEHAVIOR HERE
      //        PREVENT INFINITY
      const blocksElapsed = getBlocksElapsed(time) || 1;
      const efficiency = blocksToday / blocksElapsed;
      return toPercentageString(efficiency);
    },
    dayEfficiency: ({ blocksToday }: { blocksToday: number }) => {
      // TODO - PULL 32 FROM USER SETTINGS - NUM BLOCKS PER DAY
      const efficiency = blocksToday / 32;
      return toPercentageString(efficiency);
    },
    blocksRemaining: () => {
      const { time } = useAppStore();
      return 32 - getBlocksElapsed(time);
    },
    // TODO - MAKE INTO OBJECT?
    allTimeBlocks: (state) => {
      const total = state.engine.getTotalBlocks();
      const today = useDayStore().getDayBlocks().length;
      return total + today;
    },
    getRecentBlockSummary: (state) => (categoryId: string) => {
      return state.engine.getRecentBlockSummary(categoryId);
    },
    getCategoryMonthBlocks: (state) => (categoryId: string) => {
      const blocksToday = useDayStore()
        .getDayBlocks()
        .filter((b: Block) => {
          return b.categoryId === categoryId;
        }).length;
      const monthBlocks = state.engine.getLastNBlocks(categoryId, 30);
      return [...monthBlocks, { day: dayjs(), count: blocksToday }];
    },
    getCategoryPeriodSummary:
      (state) => (categoryId: string, period: Period) => {
        const nextPeriod = getNextPeriod(period);
        return [
          {
            label: "Blocks",
            value: state.engine.getTotalBlocks(categoryId, period),
          },
          {
            label: "Average",
            value: state.engine
              .getAverageBlocks(categoryId, period)
              .toLocaleString("en-US", {
                maximumFractionDigits: 1,
              }),
          },
          {
            active: period !== Period.ALL,
            label: `${PeriodShortLabels[period]}/${PeriodShortLabels[nextPeriod]}`,
            value:
              period === Period.ALL
                ? "-"
                : toSignedString(
                    (
                      state.engine.getAverageBlocks(categoryId, period) -
                      state.engine.getAverageBlocks(categoryId, nextPeriod)
                    ).toLocaleString("en-US", {
                      maximumFractionDigits: 1,
                    })
                  ),
            // TODO - ADD DELTA
          },
          {
            label: "Quota",
            value: `${state.engine.getTotalBlocks(
              categoryId,
              period
            )}/${state.engine.getQuotaBlocks(categoryId, period)}`,
          },
          {
            label: "Score",
            value: toPercentageString(
              state.engine.getScore(categoryId, period)
            ),
          },
          // {
          //   label: "Percentile",
          //   value: state.engine.getPercentile(categoryId, period),
          // },
          // TODO - ONLY HAVE SOME OF THESE FOR SOME PERIODS AND NOT OTHERS?
          // % of all blocks
        ].filter((s) => s.active !== false);
      },
  },
  actions: {
    setRecents(recents: any) {
      this.engine.setRecents(recents);
    },
    setTotals(totals: any) {
      this.engine.setTotals(totals);
    },
    setCategories(categories: Category[]) {
      this.engine.setCategories(categories);
    },
    setDays(days: any) {
      this.engine.setToday(days);
    },
  },
});

<script setup lang="ts">
import { useAppStore } from "@/stores/app";

const app = useAppStore();
</script>
<template>
  <Transition name="error">
    <div
      class="absolute w-full origin-top bg-red-500 p-1 text-center text-sm font-semibold text-white transition-transform"
      v-if="app.error"
    >
      <div class="mx-auto max-w-4xl">
        <p v-for="line in app.error.split('\n')" class="mb-2">{{ line }}</p>
      </div>
    </div>
  </Transition>
</template>

<style scoped>
/* TODO - SOMETHING BETTER */
.error-enter-active {
  animation: grow-in 0.3s;
}
.error-leave-active {
  animation: grow-in 0.3s reverse;
}
@keyframes grow-in {
  0% {
    transform: scaleY(0);
  }
  100% {
    transform: scaleY(1);
  }
}
</style>

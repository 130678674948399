<script setup lang="ts">
import { computed } from "vue";
import { BlockColor, CategoryPriority, DayOfWeek } from "@/types";
import BaseBlock from "@/components/BaseBlock.vue";
import { useCategoryStore } from "@/stores/category";
import { Category } from "@/objects/category";

const props = defineProps(["modelValue"]);
const emit = defineEmits(["update:modelValue"]);

// TODO - IS THIS NEEDED?
const category = computed({
  get() {
    return props.modelValue;
  },
  set(value) {
    emit("update:modelValue", value);
  },
});

// TODO - ADD MODELS
// TODO - DESCRIBE THE MEANING OF EACH PROPERTY
// TODO - SHOW HOW MANY HOURS TOTAL AND WHAT THAT MEANS RELATIVE TO HOW MANY THINGS ARE ALLOCATED ELSEWHERE
// TODO - LINK TO KNOWLEDGE BASE SECTION ON CATEGORIES

// TODO - EXMPAND THESE
const categoryNamePlaceholders = [
  "Read",
  "Exercise",
  "Meditate",
  "Code",
  "Write",
  "Work",
];
const randomCategoryName =
  categoryNamePlaceholders[
    Math.floor(Math.random() * categoryNamePlaceholders.length)
  ];

const hoursPerWeek = computed(() => {
  // TODO - METHOD CATEGORY OBJECT - THIS ISN'T CLEAR AFTER WE SUPPORT QUOTAS BEYOND SINGLE DAY
  const quotas: Record<string, number> = category.value.quotas;
  return (
    Object.values(quotas).reduce((acc, n) => {
      return acc + n;
    }, 0) / 2
  );
});

const parentCategories = computed(() => {
  return useCategoryStore()
    .categories.filter(
      (c: Category) => !c.parentId && c.id !== category.value.id
    )
    .map((c: Category) => {
      return {
        label: c.name,
        value: c.id,
      };
    })
    .sort((a: any, b: any) => {
      return a.label.localeCompare(b.label);
    });
});
</script>
<template>
  <!-- 
      TODO
      - DESCRIPTIONS AND/OR EXPLAINATIONS FOR THINGS LIKE COLOR AND QUOTA
    -->
  <div class="my-4 md:grid md:grid-cols-2 md:gap-x-4">
    <div>
      <div class="form-control">
        <label class="label">
          <span class="label-text">Name</span>
        </label>
        <input
          type="text"
          :placeholder="randomCategoryName"
          class="input-bordered input input-sm"
          v-model="category.name"
        />
      </div>

      <div class="form-control">
        <label class="label">
          <span class="label-text">Color</span>
        </label>
        <div class="flex items-center gap-x-1">
          <BaseBlock
            v-for="color in Object.keys(BlockColor).filter(
              (color) => color !== BlockColor.NEW
            )"
            :color="color as BlockColor"
            :title="color"
            filled
            @click="category.color = color as BlockColor"
            :class="category.color === color ? '-mx-2 !h-6 !w-6' : ''"
          />
        </div>
      </div>

      <div class="form-control">
        <label class="label">
          <span class="label-text">Parent</span>
        </label>
        <div>
          <select
            class="select-bordered select select-sm w-full max-w-xs"
            v-model="category.parentId"
          >
            <option :value="null">None</option>
            <option v-for="parent in parentCategories" :value="parent.value">
              {{ parent.label }}
            </option>
          </select>
        </div>
      </div>

      <div class="form-control">
        <label class="label">
          <span class="label-text">Priority</span>
        </label>
        <div>
          <select
            class="select-bordered select select-sm w-full max-w-xs"
            v-model="category.priority"
          >
            <option :value="CategoryPriority.NONE">None</option>
            <option :value="CategoryPriority.CRITICAL">Critical</option>
            <option :value="CategoryPriority.HIGH">High</option>
            <option :value="CategoryPriority.MEDIUM">Medium</option>
            <option :value="CategoryPriority.LOW">Low</option>
          </select>
        </div>
      </div>
    </div>

    <!-- TODO - PARENT SELECT - https://headlessui.com/vue/listbox -->

    <!-- TODO - EASY TOGGLE FOR EVERYDAY VS EACH DAY BREAKDOWN -->
    <!-- TODO - REFORMAT THIS TO BE SEQUENCE OF BLOCKS WITH "MARKERS"
        EX) SEE "5", "10", + total per week, year, etc.
      -->
    <!-- TODO - COUNTER THAT SHOWS TOTAL NUMBER OF BLOCKS AND WHAT THAT MEANS FOR A WEEK/MONTH/YEAR -->
    <div class="form-control">
      <label class="label">
        <span class="label-text">Quotas</span>
      </label>
      <div class="-mt-2 mb-4 ml-1 text-xs text-gray-400">
        Need more than 12 blocks? Click on the <b>+</b> to add more!
      </div>
      <!-- TODO - ADD INPUT TO SET NUMBER, TOO -->
      <!-- TODO - ADD A +ADD MORE FINAL BLOCK TO THE VERY TOP IF THE QUOTA IS HIT -->
      <!-- OR +/- BELOW EACH COLUMN TO ENABLE ADDING/REMOVING -->
      <!-- IS A BETTER UI JUST SHOWING A PICKER FOR A GIVEN DAY AND THEN DRILLING IN -->
      <!-- DO THIS TO SET SPECIFIC HOURS OR TIMINGS OF THE QUOTA? -->
      <!-- TODO - TEST THIS WITH AN EYE FOR TOUCH TARGETS -->
      <!-- TODO - ADD A LINK TO HELP WITH "HOW MANY BLOCK SHOULD I TARGET?" -->
      <!-- TODO - DIFFERENT UI ON MOBILE VS DESKTOP OR JUST PUT IN DESKTOP PANEL -->
      <div class="flex text-xs">
        <div class="mx-1 my-8 grow">
          {{ hoursPerWeek }} hours per week<br /><br />
          {{ hoursPerWeek * 4 }} hours per month<br /><br />
          {{ hoursPerWeek * 52 }} hours per year
        </div>

        <div
          v-for="day in Object.values(DayOfWeek)"
          class="flex grow-0 flex-col-reverse text-center capitalize"
          :key="`${category.quotas[day]}-${category.color}-${day}`"
        >
          <div>
            {{ day.substring(0, 2).toLowerCase() }}
          </div>
          <button
            @click="
              category.quotas[day] = Math.max(category.quotas[day] - 1, 0)
            "
          >
            -
          </button>
          <!-- TODO - FOR THE LAST BLOCK - ADD AN ICON SHOWING IT BEING HIGHER -->
          <!-- TODO - HOVER STATES BEFORE CLICK https://tailwindcss.com/docs/hover-focus-and-other-states -->
          <BaseBlock
            :color="category.color"
            :is-quota-block="false"
            v-for="(item, index) in new Array(12)"
            :filled="category.quotas[day] > index"
            class="!h-6 !w-6"
            @click="
              category.quotas[day] === index + 1
                ? (category.quotas[day] = Math.max(index - 1, 0))
                : (category.quotas[day] = index + 1)
            "
          />
          <button @click="category.quotas[day]++">+</button>
          <div>
            {{ category.quotas[day] || "&nbsp;" }}
          </div>
        </div>
      </div>
      <!-- TODO - ADD BUTTON TO DELETE - ONLY FLAG IN DB AND UPDATE QUERY -->
    </div>
  </div>
</template>
<style scoped>
.label-text {
  @apply font-semibold;
}
</style>
```

import { FirestoreDataConverter } from "firebase/firestore";

export class Config implements FirestoreDataConverter<Config> {
  readonly id: string;

  constructor({ id }: { id: string }) {
    this.id = id;
  }

  // TODO - TYPES
  toFirestore(modelObject: any): any {
    return Config.toFirestore(modelObject as Config);
  }

  // TODO - TYPES
  fromFirestore(snapshot: any, options: any): any {
    return Config.fromFirestore(snapshot, options);
  }

  static toFirestore(config: Config): any {
    throw new Error("IMPLEMENT CONFIG TO FIRESTORE");
  }

  static fromFirestore(snapshot: any, options: any): any {
    const data = { ...snapshot.data(), id: snapshot.id };
    return new Config(data);
  }

  static generateDemoData(): Config {
    return new Config({ id: "config" });
  }
}

import { Period, PeriodDays } from "@/types";
import { Category } from "@/objects/category";
import { getDayId } from "@/util";
import dayjs from "dayjs";
import { Day } from "@/objects/day";

export class Analytics {
  // TODO - TYPES
  private totals: any;
  private recents: any;
  private categories: Category[] | undefined;
  private today: Day | undefined;

  public setTotals(totals: any) {
    this.totals = totals;
  }

  public setRecents(recents: any) {
    this.recents = recents;
  }

  public setCategories(categories: Category[]) {
    this.categories = categories;
  }

  public setToday(days: Day[]) {
    this.today = days.find((d) => d.id === getDayId());
  }

  public getTotalBlocks(categoryId?: string, period: Period = Period.ALL) {
    // TODO - GROSS
    //      - THIS ISN'T PRETTY... dayBlocks are an annoying part of the equation
    //      - Need to be accounted for here because they are not in recents/totals
    if (categoryId) {
      const dayBlocks = this.today?.getCategoryBlocks(categoryId).length || 0;
      if (period === Period.ALL) {
        return (this.totals?.[`${categoryId}`] || 0) + dayBlocks;
      } else {
        const blocks = this.getRecentBlockCounts(categoryId);
        return (
          blocks
            .filter((b) =>
              b.day.isAfter(dayjs().subtract(PeriodDays[period], "day"))
            )
            .reduce((acc, b) => {
              return acc + b.count;
            }, 0) + dayBlocks
        );
      }
    } else {
      const dayBlocks = this.today?.blocks.length || 0;
      const keys = Object.keys(this.totals || {}).filter(
        (k) => k !== "_updated"
      );
      return (
        keys.reduce((acc: number, k: string) => {
          return acc + this.totals[k];
        }, 0) + dayBlocks
      );
    }
  }

  public getRecentBlockSummary(categoryId: string) {
    return {
      day: this.getTotalBlocks(categoryId, Period.DAY),
      week: this.getTotalBlocks(categoryId, Period.WEEK),
      month: this.getTotalBlocks(categoryId, Period.MONTH),
      quarter: this.getTotalBlocks(categoryId, Period.QUARTER),
      total: this.getTotalBlocks(categoryId, Period.ALL),
    };
  }

  private getRecentBlockCounts(categoryId: string) {
    // REMOVE CURRENT
    // TODO - SHOULD WE JUST MERGE THIS WITH CLIENT DATA SOMEHOW
    const currentDayId = getDayId();
    const days = Object.keys(this.recents || {}).filter(
      (k) => ![currentDayId, "_updated"].includes(k)
    );
    const counts = days.map((d) => {
      const count = this.recents[d][categoryId] || 0;
      return {
        day: dayjs(d, "YYMMDD"),
        count,
      };
    });
    return counts;
  }

  public getLastNBlocks(categoryId: string, n: number) {
    const blocks = this.getRecentBlockCounts(categoryId);
    const startDay = dayjs().subtract(n, "day");
    const filtered = blocks.filter((b) => b.day.isAfter(startDay));
    return filtered;
  }

  public getAverageBlocks(categoryId: string, period: Period) {
    const category = this.categories?.find((c) => c.id === categoryId);
    if (!category) {
      console.error("CATEGORY NOT FOUND", categoryId);
      return 0;
    }

    const total = this.getTotalBlocks(categoryId, period);
    const days = period === Period.ALL ? category.daysOld : PeriodDays[period];
    return total / days;
  }

  public getQuotaBlocks(categoryId: string, period: Period) {
    const category = this.categories?.find((c) => c.id === categoryId);
    if (!category) {
      console.error("CATEGORY NOT FOUND", categoryId);
      return 0;
    }
    return category.getPeriodQuota(period);
  }

  public getScore(categoryId: string, period: Period) {
    const quota = this.getQuotaBlocks(categoryId, period);
    const blocks = this.getTotalBlocks(categoryId, period);
    return blocks / quota;
  }

  public getPercentile(categoryId: string, period: Period) {
    return -1;
  }
}

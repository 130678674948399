<script setup lang="ts">
import { computed, ref, watch } from "vue";
import { BlockColor, DayOfWeek } from "@/types";

import CategoryForm from "@/components/CategoryForm.vue";

import { useCategoryStore } from "@/stores/category";
import router from "@/router";
import { Category } from "@/objects/category";
const { id } = defineProps<{ id: string }>();

const { updateCategory, getCategory, deleteCategory } = useCategoryStore();

const update = async () => {
  // TODO - REQUIRE TITLE AND COLOR TO BE SET
  //      - Category.validate?
  try {
    await updateCategory(category.value);
    router.push("/");
  } catch (e) {
    // TODO - ALERT SOMEWHERE/SOMEHOW
    console.error("There was a problem updating the category", e);
  }
};

const category = ref(Category.getDefaultObject());
const existingCategory = computed(() => {
  return getCategory(id) || Category.getDefaultObject();
});

const confirmDelete = async () => {
  const really = confirm(
    `Delete ${category.value.name}? This cannot be undone and will delete all associated subcategories.`
  );

  if (!really) return;

  await deleteCategory(category.value.id);
  router.push("/");
};

// POSSIBLE TODO - USE WATCH EFFECT INSTEAD?
// TO ACCOUNT FOR CATEGORIES LOADING, START WITH DEFAULT AND THEN UPDATE
watch(
  existingCategory,
  (newVal: any) => {
    // TODO - USE toFirestore?!
    category.value = {
      ...existingCategory.value,
      quotas: { ...existingCategory.value.quotas },
    };
  },
  { immediate: true }
);
</script>
<template>
  <div class="mr-auto max-w-2xl">
    <h3>Edit Category</h3>
    <CategoryForm v-model="category" />
    <div class="flex justify-between">
      <button @click="router.push('/')" class="btn-outline btn-sm btn">
        Cancel
      </button>
      <button
        @click="confirmDelete"
        class="btn-outline btn-sm btn border-red-500 text-red-500 hover:border-red-600 hover:bg-red-600 hover:text-white"
      >
        Delete
      </button>
      <button @click="update" class="btn-sm btn">Update</button>
    </div>
  </div>
</template>

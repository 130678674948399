<script setup lang="ts">
import { useCategoryStore } from "@/stores/category";
import { storeToRefs } from "pinia";
import { computed, ref } from "vue";
import { Period } from "@/types";
import { useAnalyticsStore } from "@/stores/analytics";

// TODO - PULL MORE DATA FOR RECENTS
const periods = [
  { label: "1D", value: Period.DAY },
  { label: "1W", value: Period.WEEK },
  { label: "1M", value: Period.MONTH },
  { label: "3M", value: Period.QUARTER },
  // { label: "6M", value: Period.HALF_YEAR },
  // { label: "1Y", value: Period.YEAR },
  { label: "All", value: Period.ALL },
];

// TODO - SET THIS SOMEWHERE AND SAVE IT
const activePeriod = ref(periods[1].value);
const { activeCategory } = storeToRefs(useCategoryStore());
const analytics = useAnalyticsStore();

const stats = computed(() => {
  return analytics.getCategoryPeriodSummary(
    activeCategory.value.id,
    activePeriod.value
  );
});
</script>

<template>
  <div>
    <div class="flex justify-between border-b">
      <button
        v-for="period in periods"
        :key="period.value"
        class="px-1.5 text-xs uppercase"
        :class="{
          'font-bold text-white': activePeriod === period.value,
        }"
        :style="{
          'background-color':
            activePeriod === period.value
              ? activeCategory.colorHex
              : 'transparent',
        }"
        @click="activePeriod = period.value"
      >
        {{ period.label }}
      </button>
    </div>
    <div class="flex justify-between">
      <div
        v-for="{ label, value, key } in stats"
        class="pt-2 text-center text-xs"
      >
        {{ value }}
        <div class="metric-label">
          {{ label }}
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped></style>

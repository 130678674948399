<script setup lang="ts">
import * as Sentry from "@sentry/browser";

Sentry.captureMessage(`Page not found - ${window.location.href}`);

setTimeout(() => {
  window.location.href = "/";
}, 1000);
</script>

<template>
  <!-- TODO - BETTER ERROR MESSAGE -->
  <h1>Not Found</h1>
</template>
<style scoped></style>

import dayjs, { Dayjs } from "dayjs";
import { DayOfWeek, Period } from "@/types";

export const DAY_ID_FORMAT = "YYMMDD";

// TODO - PULL OFFSET FROM USER SETTINGS
export const getDayId = (d: Dayjs = dayjs(), hourOffset = 4, dayOffset = 0) =>
  d
    .subtract(hourOffset, "hour")
    .subtract(dayOffset, "day")
    .format(DAY_ID_FORMAT);

export const getDayOfWeek = (d: Dayjs = dayjs()) => {
  const day = d.format("dddd").toUpperCase();
  return DayOfWeek[day as keyof typeof DayOfWeek];
};

export const getLastNDays = (n: number, d: Dayjs = dayjs()) => {
  const days = [];
  for (let i = 0; i < n; i++) {
    days.push(d.subtract(i, "day"));
  }
  return days;
};

// GROSS - USE A LIBRARY OR SOMETHING
export const getNSurroundingDays = (
  n: number,
  d: Dayjs = dayjs(),
  max?: Dayjs
) => {
  const daysFromMax = Math.abs(d.diff(max || dayjs(), "day"));
  const daysAhead = Math.min(daysFromMax, Math.floor(n / 2));
  const daysBehind = n - daysAhead;

  const days = [d];
  for (let i = 1; i < daysAhead; i++) {
    days.push(d.add(i, "day"));
  }
  for (let i = 1; i <= daysBehind; i++) {
    days.unshift(d.subtract(i, "day"));
  }

  return days;
};

// TODO - PULL OFFSET FOR WHEN DAY STARTS FROM USER SETTINGS
//      - THIS STARTS AT 6AM
export const getBlocksElapsed = (d: Dayjs = dayjs(), offset = 6) => {
  const elapsed = d.subtract(offset, "hour");
  // TWO BLOCKS PER HOUR
  return elapsed.hour() * 2 + Math.floor(elapsed.minute() / 30);
};

export const toPercentageString = (n: number) => {
  if (isNaN(n) || !isFinite(n)) {
    return "-";
  }

  return `${(n * 100).toLocaleString(undefined, {
    maximumFractionDigits: 1,
  })}%`;
};

export const setCookie = (name: string, value: string, days: number) => {
  const date = new Date();
  date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
  const expires = "; expires=" + date.toUTCString();
  document.cookie = name + "=" + (value || "") + expires + "; path=/";
};

export const isMobile = () => {
  const elem: HTMLElement | null = document.querySelector("#is-mobile-pixel");
  return elem?.clientWidth === 0;
};

export const getNextPeriod = (period: Period) => {
  const periods = Object.values(Period);
  const index = periods.indexOf(period);
  if (index === -1) {
    return Period.ALL;
  }

  return periods[(index + 1) % periods.length];
};

export const toSignedString = (n: string | number) => {
  const parsed = typeof n === "string" ? parseFloat(n) : n;
  return parsed > 0 ? `+${n}` : `${n}`;
};

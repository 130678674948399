// TODO - EXTERNAL SO THIS CAN BE USED NOT JUST IN THE UI - MONOREPO?
import { Timestamp } from "firebase/firestore";

// /////////////////////////
// BIG PICTURE ARCHITECTURE
// - Extend Firebase Objects with references?
// - Extend all objects to have created/updated?
// /////////////////////////

// //////////////////////////////////////////////////////////////////
// ______ _____ _____  ______  _____ _______ ____  _____  ______
// |  ____|_   _|  __ \|  ____|/ ____|__   __/ __ \|  __ \|  ____|
// | |__    | | | |__) | |__  | (___    | | | |  | | |__) | |__
// |  __|   | | |  _  /|  __|  \___ \   | | | |  | |  _  /|  __|
// | |     _| |_| | \ \| |____ ____) |  | | | |__| | | \ \| |____
// |_|    |_____|_|  \_\______|_____/   |_|  \____/|_|  \_\______|
// //////////////////////////////////////////////////////////////////

export type FirestoreStructure = {
  users: {
    [userId: string]: {
      config: {
        latest: Config;
        [date: string]: Config;
      };
      days: {
        [date: string]: {
          blocks: Array<Block>;
          configId: string;
          _updated?: Timestamp;
          // TODO - ITERATE ON ALL OF THESE WHEN THEY ARE NOT EQUAL
          _processed?: Timestamp;
        };
      };
      analytics: {
        [year: string]:
          | {
              [categoryId: string]: {
                [month: string]: {
                  [day: string]: number;
                };
              };
            }
          | any; // STUPID HACK TO GET TS TO CHILL
        recent: {
          _updated: Timestamp;
          [categoryId: string]:
            | {
                // FILL ME IN
                [dayId: string]: number;
              }
            | Timestamp; // STUPID HACK TO GET TS TO CHILL
        };
        totals: {
          _updated: Timestamp;
          total: number;
          [categoryId: string]: number | Timestamp;
        };
      };
    };
  };
  // GLOBAL ANALYTICS? CONFIG?
};

// ///////////////////////////
// ___ _   _ ___  ____ ____
//  |   \_/  |__] |___ [__
//  |    |   |    |___ ___]
// ///////////////////////////
export type Block = {
  _created: Timestamp;
  id: string;
  categoryId: string;
  // TODO - INTENSITY,IS_QUOTA, ETC?
};

export type Config = {
  categories: Array<Category>;
  // START TIME FOR DAY
  // BLOCK INCREMENTS
  // PRO VS NOT SUBSCRIBER
  // TODO - VALID RANGE/UPDATED/ETC
};

export type Category = {
  _created: Timestamp;
  _updated: Timestamp;
  id: string;
  name: string;
  color: BlockColor;
  parentId?: string;
  ordering?: number;
  // TODO - ADD QUOTA TYPE OR JUST ADD AS A PROPERTY AND KEY ACCORDINGLY?
  quotas: {
    // THIS IS GROSS BUT EASIER NOW THAN FIGHTING WITH TYPESCRIPT
    [DayOfWeek.SUNDAY]?: number;
    [DayOfWeek.MONDAY]?: number;
    [DayOfWeek.TUESDAY]?: number;
    [DayOfWeek.WEDNESDAY]?: number;
    [DayOfWeek.THURSDAY]?: number;
    [DayOfWeek.FRIDAY]?: number;
    [DayOfWeek.SATURDAY]?: number;
    // WEEKLY QUOTA
    // MONTHLY QUOTA
    // YEARLY QUOTA?
  };
  // TODO - Weight/Priority
};

export type Milestones = {
  welcomeCompleted?: Timestamp;
  firstBlock?: Timestamp;
};

export type User = {
  _created: Timestamp;
  _lastSeen: Timestamp;
  // TODO - ADD CHECKS TO MAKE SURE THIS CAN ONLY BE SET BY TY
  _isDev: boolean;
  uid: string;
  name: string;
  email: string;
  photoURL: string;
  providerData: any[];
  milestones: Milestones;
};

// SHARED TYPES ACROSS TY PROJECTS? WOULD NEED TO BE VERSIONED?
export type WizardStep = {
  type: WizardStepType;
  // TODO - TYPE SO ONLY IF WE ARE USING A TEXT QUESTION
  placeholder?: string;
  text: string;
  actions: WizardAction[];
  required?: boolean;
  onComplete?: (...params: any) => void;
  onSkip?: (...params: any) => void;
  filter?: (...params: any) => boolean;
};

export type WizardAction = {
  label: string;
  // Is this the correct type for functions?
  callback: (...params: any) => void;
};

// /////////////////
// __          __
// |_ |\|| ||V|(_
// |__| ||_|| |__)
// /////////////////
export enum WizardStepType {
  TEXT = "TEXT",
  TEXT_QUESTION = "TEXT_QUESTION",
  // TODO - RENAME
  BUTTON_QUESTION = "BUTTON_QUESTION",
}

export enum BlockColor {
  RED = "RED",
  ORANGE = "ORANGE",
  YELLOW = "YELLOW",
  GREEN = "GREEN",
  TURQUOISE = "TURQUOISE",
  BLUE = "BLUE",
  PURPLE = "PURPLE",
  PINK = "PINK",
  LIGHTGRAY = "LIGHTGRAY",
  DARKGRAY = "DARKGRAY",
  BLACK = "BLACK",
  WHITE = "WHITE",
  NEW = "NEW",
}

// TODO - KEEP THIS IN SYNC WITH BaseBlock.vue
export enum BlockColorHex {
  RED = "#ff3a24",
  ORANGE = "#e67e22",
  YELLOW = "#f1c40f",
  GREEN = "#2ecc71",
  TURQUOISE = "#1abc9c",
  BLUE = "#3498db",
  PURPLE = "#9b59b6",
  PINK = "#ff2470",
  LIGHTGRAY = "#ecf0f1",
  DARKGRAY = "#95a5a6",
  BLACK = "#34495e",
  WHITE = "#fff",
  NEW = "transparent",
}

export enum CategoryPriority {
  CRITICAL = 1,
  HIGH = 2,
  MEDIUM = 3,
  LOW = 4,
  NONE = 5,
}

export const CategoryPriorityLabels: any = {
  [CategoryPriority.CRITICAL]: "Critical",
  [CategoryPriority.HIGH]: "High",
  [CategoryPriority.MEDIUM]: "Medium",
  [CategoryPriority.LOW]: "Low",
  [CategoryPriority.NONE]: "None",
};

export const CategoryPriorityColors = {
  [CategoryPriority.CRITICAL]: BlockColor.RED,
  [CategoryPriority.HIGH]: BlockColor.ORANGE,
  [CategoryPriority.MEDIUM]: BlockColor.YELLOW,
  [CategoryPriority.LOW]: BlockColor.GREEN,
  [CategoryPriority.NONE]: BlockColor.LIGHTGRAY,
};

export enum DayOfWeek {
  SUNDAY = "SUNDAY",
  MONDAY = "MONDAY",
  TUESDAY = "TUESDAY",
  WEDNESDAY = "WEDNESDAY",
  THURSDAY = "THURSDAY",
  FRIDAY = "FRIDAY",
  SATURDAY = "SATURDAY",
}

export enum MembershipLevel {
  FREE = "FREE",
  PRO = "PRO",
  OG = "OG",
  DEMO = "DEMO",
}

export const MembershipLevelLabel: any = {
  [MembershipLevel.FREE]: "Free",
  [MembershipLevel.PRO]: "Pro",
  [MembershipLevel.OG]: "Founding Member",
};

export enum Period {
  DAY = "DAY",
  WEEK = "WEEK",
  MONTH = "MONTH",
  QUARTER = "QUARTER",
  HALF_YEAR = "HALF_YEAR",
  YEAR = "YEAR",
  ALL = "ALL",
}

export enum PeriodDays {
  DAY = 1,
  WEEK = 7,
  MONTH = 30,
  QUARTER = 90,
  HALF_YEAR = 180,
  YEAR = 365,
  // THIS WAS INFINITY AND THEN WHEN WE FORGET TO
  // PIVOT THIS TO CATEGORY AGE, LOOP, LOOP, LOOP!
  ALL = 100000,
}

export enum PeriodShortLabels {
  DAY = "1D",
  WEEK = "1W",
  MONTH = "1M",
  QUARTER = "3M",
  HALF_YEAR = "6M",
  YEAR = "1Y",
  ALL = "ALL",
}

import {
  DocumentData,
  FirestoreDataConverter,
  PartialWithFieldValue,
  QueryDocumentSnapshot,
  SetOptions,
  SnapshotOptions,
  Timestamp,
  WithFieldValue,
} from "firebase/firestore";
import { Block } from "@/objects/block";
import dayjs, { Dayjs } from "dayjs";
import { DAY_ID_FORMAT } from "@/util";

export class Day implements FirestoreDataConverter<Day> {
  readonly id: string;
  readonly blocks: Block[];
  readonly date: Dayjs;
  readonly _updated: Timestamp | undefined;
  readonly _processed: Timestamp | undefined;

  // TODO - INTERFACE?
  constructor({
    id,
    blocks,
    _updated,
    _processed,
  }: {
    id: string;
    blocks: Block[];
    _updated?: Timestamp;
    _processed?: Timestamp;
  }) {
    this.id = id;
    this.blocks = blocks;
    this.date = dayjs(id, DAY_ID_FORMAT);
    console.warn("TODO - SET CONFIG ID");
    this._updated = _updated;
    this._processed = _processed;
  }

  public getCategoryBlocks(categoryId: string) {
    return this.blocks.filter((b) => b.categoryId === categoryId);
  }

  toFirestore(modelObject: WithFieldValue<Day>): DocumentData;
  toFirestore(
    modelObject: PartialWithFieldValue<Day>,
    options: SetOptions
  ): DocumentData;
  toFirestore(modelObject: unknown, options?: unknown): DocumentData {
    return Day.toFirestore(modelObject as Day);
  }

  fromFirestore(
    snapshot: QueryDocumentSnapshot<DocumentData>,
    options?: SnapshotOptions | undefined
  ): Day {
    return Day.fromFirestore(snapshot, options);
  }

  static toFirestore(block: any): any {
    throw new Error("TODO - IMPLEMENT Day.toFirestore");
  }

  static fromFirestore(snapshot: any, options: any): any {
    const data = { ...snapshot.data(), id: snapshot.id };
    data.blocks = data.blocks.map(
      (b: any) => new Block({ ...b, dayId: data.id })
    );
    return new Day(data);
  }

  static generateDemoData(): Day[] {
    // TODO - IMPLEMENT ME
    const id = dayjs().format(DAY_ID_FORMAT);
    return [new Day({ id, blocks: [] })];
  }
}

import { firestore } from "@/firebase";
import {
  onSnapshot,
  doc,
  query,
  collection,
  DocumentSnapshot,
  DocumentData,
  QuerySnapshot,
  where,
} from "firebase/firestore";

import { User } from "@/objects/user";
import { Day } from "@/objects/day";
import { Category } from "@/objects/category";
import { Config } from "@/objects/config";
import { DAY_ID_FORMAT, getDayId } from "@/util";
import dayjs from "dayjs";

interface EngineHooks {
  // TODO - CONFIRM DATA TYPE IN FUNCTION
  user: (data: any) => void;
  categories: (data: any) => void;
  days: (data: any) => void;
  config: (data: any) => void;
  analyticsTotals: (data: any) => void;
  analyticsRecents: (data: any) => void;
}

// TODO - MOVE ALL CONFIG TO ACTUAL CLASSES? PROBABLY DOESN'T NEED
//        TO BE HERE?
const hookConfig = {
  user: {
    key: "user",
    query: (uid: string) => doc(firestore, "users", uid),
    converter: User,
  },
  categories: {
    key: "categories",
    // TODO - CHANGE THIS SO WE'RE LOADING FROM THE CONFIG DIRECTLY?
    query: (uid: string) => collection(firestore, "users", uid, "categories"),
    converter: Category,
  },
  days: {
    // TODO - LOAD SINGLE DAY, OTHERWISE PULL ANALYTICS OBJECT
    key: "days",
    query: (uid: string) => {
      const daysRef = collection(firestore, "users", uid, "days");
      return query(
        daysRef,
        where("__name__", ">=", getDayId(undefined, undefined, 7))
      );
    },
    converter: Day,
  },
  config: {
    key: "config",
    query: (uid: string) => doc(firestore, `users/${uid}/config/latest`),
    converter: Config,
  },
  analyticsTotals: {
    key: "analyticsTotals",
    query: (uid: string) => doc(firestore, `users/${uid}/analytics/totals`),
    converter: {
      fromFirestore: (snapshot: any, options: any) => {
        return snapshot.data();
      },
    },
  },
  analyticsRecents: {
    key: "analyticsRecents",
    query: (uid: string) => doc(firestore, `users/${uid}/analytics/recents`),
    converter: {
      fromFirestore: (snapshot: any, options: any) => {
        const data = snapshot.data();
        const NUM_DAYS = 90;
        // ACCOUNT FOR MISSING DAYS IN RECENTS DOCUMENT
        for (let d = 0; d < NUM_DAYS; d++) {
          const dayId = getDayId(undefined, undefined, d);
          if (!data[dayId]) {
            data[dayId] = {
              day: dayjs(dayId, DAY_ID_FORMAT),
            };
          }
        }
        return data;
      },
    },
  },
};

export class Engine {
  readonly uid: string;
  private initialized = false;
  private hooks: EngineHooks;
  private unsubscribes: any;

  constructor(uid: string, hooks: EngineHooks) {
    this.uid = uid;
    this.hooks = hooks;
    console.green("Engine created!", this.uid, this.hooks);
    this.attachSnapshotListeners();
  }

  public get isInitialized(): boolean {
    return this.initialized;
  }

  private async attachSnapshotListeners() {
    if (this.initialized) {
      throw new Error("Engine already initialized");
    }
    this.unsubscribes = await Promise.all(
      Object.values(hookConfig).map((hook: any) => {
        const unsubscribe = onSnapshot(
          hook.query(this.uid).withConverter(hook.converter),
          (snapshot: DocumentSnapshot | QuerySnapshot) => {
            const isCollection = snapshot instanceof QuerySnapshot;
            const data = isCollection
              ? snapshot.docs.map((d: DocumentData) => d.data())
              : snapshot.data();

            const callback = this.hooks[hook.key as keyof EngineHooks];
            if (!callback) {
              throw new Error("No callback found for hook: ", hook.key);
            }
            callback(data);
          }
        );
        return unsubscribe;
      })
    );
    this.initialized = true;
  }

  public async detachSnapshotListeners() {
    return this.unsubscribes.forEach((unsubscribe: any) => unsubscribe());
  }
}

<script setup lang="ts">
import { useAuthStore } from "@/stores/auth";
import { useAppStore } from "@/stores/app";
import { storeToRefs } from "pinia";
import { ref } from "vue";

const auth = useAuthStore();

const errorFree = ref(true);

const { isDemo } = storeToRefs(useAppStore());
</script>

<template>
  <!-- TODO - ADJUST FOR MOBILE -->
  <!-- TODO - CLEAN THIS UP -->
  <div
    class="fixed inset-x-0 top-0 z-50 flex w-full items-center justify-between border-b bg-white bg-opacity-95 px-4 py-1 text-stone-500 lg:px-8"
  >
    <!-- TODO - FIX SPACING -->
    <div
      class="mx-auto flex w-full max-w-4xl items-center justify-between text-sm lg:px-4"
    >
      <div class="flex flex-row items-center">
        <router-link to="/" class="">
          <div class="flex items-center justify-center text-black">
            <img src="/favicon.svg" class="mr-1 h-6" alt="Talloc Logo" />
            Talloc
          </div>
        </router-link>
      </div>
      <div class="flex items-center gap-x-1.5">
        <!-- TODO - BETTER SPACING -->
        <router-link to="/help" class=""> Help </router-link>
        <span class="w-[1px] border-r">&nbsp;</span>
        <router-link to="/profile" :class="!errorFree ? '-mb-1' : ''">
          <img
            v-if="auth?.user?.photoURL && errorFree"
            :src="auth?.user?.photoURL"
            @error="errorFree = false"
            class="h-6 rounded-full"
          />
          <Icon name="account_circle" v-else class="h-4" />
        </router-link>
      </div>
    </div>
  </div>
</template>

<style scoped></style>

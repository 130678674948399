<script setup lang="ts">
import { useRoute } from "vue-router";
import { storeToRefs } from "pinia";

import Navigation from "@/components/Navigation.vue";
import MessageBar from "@/components/MessageBar.vue";

import { useAuthStore } from "@/stores/auth";
import { useAppStore } from "@/stores/app";
import { computed, onMounted, watchEffect } from "vue";

const route = useRoute();

// TOSO - CHANGE TO REFS OR KILL COMPLETELY
// TODO - CHANGE TO ROUTER PROP?
const { isSignedIn } = storeToRefs(useAuthStore());
const { isDemo, navTitle } = storeToRefs(useAppStore());

onMounted(() => {
  window.addEventListener("resize", () => {
    useAppStore().refreshIsMobile();
  });
  useAppStore().refreshIsMobile();
});

watchEffect(() => {
  document.title = navTitle.value;
});
</script>

<template>
  <div class="flex flex-col justify-between">
    <Navigation v-if="isSignedIn || isDemo" />
    <MessageBar class="-mt-0.5" />
    <main class="mx-auto w-full max-w-4xl grow p-4 pt-12">
      <router-view :key="route.fullPath" />
    </main>
    <div class="hidden md:block" id="is-mobile-pixel"></div>
  </div>
</template>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

/* TODO - MOVE TO CSS FILE */
input:focus,
select:focus,
input:focus-visible,
select:focus-visible {
  @apply !border-blue-500 !outline-1 !outline-offset-0 !outline-blue-500 !ring-1 !ring-blue-500;
}
</style>

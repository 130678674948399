<script setup lang="ts">
import UserHome from "@/views/UserHome.vue";
import LandingPage from "@/views/LandingPage.vue";
import { useAuthStore } from "@/stores/auth";
import { computed } from "vue";

const authStore = useAuthStore();

// TODO - GLOBAL LOAD OF SETTINGS?
// TODO - BREAK OUT INTO SINGLE INIT COMMAND ACROSS EVERYTHING?
const isInitialized = computed(() => authStore.initialized);
const isSignedIn = computed(() => authStore.isSignedIn);
</script>

<template>
  <!-- TODO - ERROR STATE/DETECTION HERE IF WE NEVER COME BACK ONLINE -->
  <template v-if="isInitialized">
    <UserHome v-if="isSignedIn" />
    <LandingPage v-else />
    <!-- TODO - ADD VERSION NUMBER + RELEASE NOTES -->
  </template>
</template>

<style scoped></style>

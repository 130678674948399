<script setup lang="ts">
import { ref } from "vue";
import CategoryForm from "@/components/CategoryForm.vue";

import { useCategoryStore } from "@/stores/category";
import router from "@/router";
import { Category } from "@/objects/category";
const { createCategory } = useCategoryStore();

const create = async () => {
  // TODO - REQUIRE TITLE AND COLOR OT BE SET
  try {
    await createCategory(category.value);
    router.push("/");
  } catch (e) {
    // TODO - ALERT SOMEWHERE/SOMEHOW
    console.error("There was a problem creating a new category", e);
  }
};

const category = ref(Category.getDefaultObject());
</script>
<template>
  <div>
    <h3>New Category</h3>
    <CategoryForm v-model="category" />
    <button @click="create" class="btn-sm btn">Create</button>
  </div>
</template>

import { defineStore } from "pinia";
import {
  addDoc,
  arrayUnion,
  collection,
  CollectionReference,
  doc,
  DocumentData,
  DocumentReference,
  serverTimestamp,
  updateDoc,
} from "firebase/firestore";
import { firestore } from "@/firebase";
import { useAuthStore } from "./auth";
import { BlockColor } from "@/types";
import { Category } from "@/objects/category";
import { sendEvent } from "@/analytics";
import { useAppStore } from "@/stores/app";

export const useCategoryStore = defineStore("category", {
  state: (): any => {
    return {
      initialized: false,
      userCategories: [],
      demoCategories: Category.generateDemoData(),
      activeCategoryId: "",
    };
  },
  // TODO - USE THIS EVERYWHERE WITH CONVERTERS
  getters: {
    categories: (state) => {
      return useAppStore().isDemo ? state.demoCategories : state.userCategories;
    },
    getCategoryColor:
      (state) =>
      (categoryId: string): BlockColor => {
        // TODO - TYPE
        const category = state.categories.find(
          (c: Category) => c.id === categoryId
        );
        // TODO - FREAK OUT IF THIS IS EVER UNDEFINED
        return category?.color;
      },
    getCategory: (state) => (categoryId: string) => {
      // TODO - ADD TYPE
      return state.categories.find((c: any) => c.id === categoryId);
    },
    getCategoryRef: () => (categoryId: string) => {
      // TODO - ADD TYPE
      const { uid } = useAuthStore();
      return doc(firestore, `/users/${uid}/categories/${categoryId}`);
    },
    getCategories: (state) => {
      return state.categories;
    },
    // TODO - PULL FROM ACTIVE PREFERENCES/SETTINGS
    sortedCategories: (state) => {
      return state.categories.sort((a: Category, b: Category) => {
        return a.priority - b.priority;
      });
    },
    parentCategories: (state) => {
      return state.sortedCategories.filter((c: Category) => !c.parentId);
    },
    getSubCategories: (state) => (parentId: string) => {
      return state.sortedCategories.filter(
        (c: Category) => c.parentId === parentId
      );
    },
    categoryCollectionRef(): CollectionReference<DocumentData> {
      const userId = useAuthStore().uid;
      if (!userId) {
        throw new Error("UID Undefined");
      }

      return collection(firestore, `users/${userId}/categories`);
    },
    latestConfigRef(): DocumentReference<DocumentData> {
      const userId = useAuthStore().uid;
      // TODO - ADD CONVERTER
      return doc(firestore, `users/${userId}/config/latest`);
    },
    activeCategory(): Category {
      return this.getCategory(this.activeCategoryId);
    },
  },
  actions: {
    // TODO - ADD TYPE
    async createCategory(data: any) {
      console.log("Creating category: ", data);
      console.log("ADD TRANSACTION HERE FOR DOUBLE WRITE WITH CONFIG");

      // TODO - CONFIGM SHAPE
      const d = await addDoc(this.categoryCollectionRef, {
        ...data,
        _created: serverTimestamp(),
        _updated: serverTimestamp(),
      });

      sendEvent("category-create");
      return d;
      // TODO - DOUBLE WRITE TO CATEGORY COLLECTION + LATEST
      //      - IN TRANSACTION
      //      - DO IN ENGINE

      // return updateDoc(this.latestConfigRef, {
      //   categories: arrayUnion({ id, name, color: randomColor }),
      // });
    },
    async updateCategory(data: any) {
      console.log("ADD TRANSACTION HERE FOR DOUBLE WRITE WITH CONFIG");
      const d = await updateDoc(this.getCategoryRef(data.id), {
        ...data,
        _updated: serverTimestamp(),
      });
      sendEvent("category-update");
      return d;
    },
    async deleteCategory(id: string) {
      console.log("ADD TRANSACTION HERE FOR DOUBLE WRITE WITH CONFIG");
      const d = await updateDoc(this.getCategoryRef(id), {
        _deleted: serverTimestamp(),
      });

      sendEvent("category-delete");
      return d;
    },
    setCategories(categories: Category[]) {
      this.userCategories = (categories || []).filter((c) => !c._deleted);
    },
    setActiveCategoryId(id: string) {
      this.activeCategoryId = id;
    },
  },
});

import { defineStore } from "pinia";
import { firestore } from "@/firebase";
import { doc, updateDoc } from "firebase/firestore";
import { useAuthStore } from "@/stores/auth";
import { User } from "@/objects/user";
import { useAppStore } from "./app";

export const useUserStore = defineStore("user", {
  state: (): {
    user: User | null;
    numCalls: number;
    initialized: boolean | Promise<boolean>;
  } => {
    return {
      user: null,
      numCalls: 0,
      initialized: false,
    };
  },
  getters: {
    isNewUser(state): boolean {
      return !!state.user?.isNewUser;
    },
    hasFirstBlock(state): boolean {
      return !!state.user?.hasFirstBlock;
    },
    name(state): string | undefined {
      // TODO - FALLBACK TO OAUTH NAME SET AND THEN EMAIL
      return state.user?.name;
    },
    uid(): string | undefined {
      const auth = useAuthStore();
      return auth.uid;
    },
  },
  actions: {
    async setUserProperty(property: string, value: any) {
      if (!this.uid) {
        return console.error("Unable to load settings - UID undefined...");
      }

      return updateDoc(doc(firestore, `users/${this.uid}`), {
        [property]: value,
      });
    },
    setUser(user: User) {
      this.user = user;

      // TODO - BETTER WAY TO DETECT IF WE HAVE LOADED AN ACTUAL USER
      //        SOME CHANGE IN THE USER OBJECT?
      if (user?.uid) {
        this.initialized = true;
      }

      if (this.initialized && useAppStore().isDev && !this.user?._isDev) {
        console.warn("User isn't a dev!");
        console.log(this.user);
        return useAuthStore().signOut();
      }

      const authUser = useAuthStore().user;
      // Keep user data synced
      const payload: any = {};
      if (authUser?.email && authUser?.email !== user?.email) {
        payload.email = authUser?.email;
      }
      if (authUser?.displayName && authUser?.displayName !== user?.name) {
        payload.name = authUser?.displayName;
      }
      if (authUser?.photoURL && authUser?.photoURL !== user?.photoURL) {
        payload.photoURL = authUser?.photoURL;
      }

      if (Object.keys(payload).length) {
        console.log("Updating user data...");
        return updateDoc(doc(firestore, `users/${this.uid}`), payload);
      }
    },
  },
});

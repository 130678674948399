import { MembershipLevel, MembershipLevelLabel, Milestones } from "@/types";
import { FirestoreDataConverter, Timestamp } from "firebase/firestore";

export class User implements FirestoreDataConverter<User> {
  readonly uid: string;
  readonly _isDev: boolean;
  readonly name?: string;
  readonly email?: string;
  readonly photoURL?: string;
  // TODO - FIX TYPE
  readonly milestones?: Milestones;
  readonly membership: MembershipLevel = MembershipLevel.FREE;

  constructor({
    uid,
    name,
    email,
    photoURL,
    milestones,
    membership = MembershipLevel.FREE,
    _isDev,
  }: {
    uid: string;
    name: string;
    email: string;
    photoURL?: string;
    milestones: Milestones;
    membership: MembershipLevel;
    _isDev: boolean;
  }) {
    this.uid = uid;
    this.name = name;
    this.email = email;
    this.photoURL = photoURL;
    this.milestones = milestones;
    this.membership = membership;
    this._isDev = _isDev;
  }

  get membershipLabel(): string {
    return MembershipLevelLabel[this.membership];
  }

  get isNewUser(): boolean {
    return !this.milestones?.welcomeCompleted;
  }

  get hasFirstBlock(): boolean {
    return !!this.milestones?.firstBlock;
  }

  toFirestore(modelObject: any): any {
    return User.toFirestore(modelObject as User);
  }

  fromFirestore(snapshot: any, options: any): any {
    return User.fromFirestore(snapshot, options);
  }

  static toFirestore(user: User): any {
    throw new Error("TODO - IMPLEMENT User.toFirestore");
  }

  static fromFirestore(snapshot: any, options: any): any {
    return new User(snapshot.data());
  }

  static generateDemoData(): User {
    return new User({
      uid: "demo-user",
      name: "Demo User",
      email: "",
      milestones: {
        // TODO - FIX
        welcomeCompleted: true as unknown as Timestamp,
        firstBlock: true as unknown as Timestamp,
      },
      _isDev: true,
      membership: MembershipLevel.DEMO,
    });
  }
}

<script setup lang="ts">
import BaseBlock from "@/components/BaseBlock.vue";
import { Block } from "@/objects/block";
import { useAnalyticsStore } from "@/stores/analytics";
import { useCategoryStore } from "@/stores/category";
import { useDayStore } from "@/stores/day";
import { BlockColor } from "@/types";
import { storeToRefs } from "pinia";
import { computed } from "vue";

const analytics = useAnalyticsStore();

const {
  blocksToday,
  blocksRemaining,
  quotaBlocksToday,
  quotaBlocksCompleted,
  timeSinceLastBlock,
  runningEfficiency,
  dayEfficiency,
} = storeToRefs(analytics);

const completedBlockColors = computed(() => {
  const blocks = useDayStore().getDayBlocks();
  const { getCategoryColor } = useCategoryStore();
  return blocks.map((block: Block) => getCategoryColor(block.categoryId));
});
</script>

<template>
  <!-- TODO - HOVER EFFECT? -->
  <div class="my-2 flex items-center justify-center text-[0.5rem]">
    <div class="mr-1 mt-0.5">{{ 32 - blocksRemaining }}</div>

    <BaseBlock
      v-for="(color, i) in completedBlockColors"
      :key="i + (color as BlockColor)"
      :color="color as BlockColor"
      class="!m-0 !h-3 !w-3 border-white"
      filled
    />
    <div
      v-for="(b, i) in Array(Math.max(32 - completedBlockColors.length, 0))"
      class="!h-3 !w-3 border border-white"
      :class="{
        // 'bg-black': i <= 32 - blocksRemaining - completedBlockColors.length,
        // 'bg-white': i > 32 - blocksRemaining - completedBlockColors.length,
        'bg-gray-200': true,
        // '!bg-gray-400':
        //   i === 32 - blocksRemaining - completedBlockColors.length,
        'z-50 -m-1 border-2 bg-[#00ff00] p-2':
          i === 32 - blocksRemaining - completedBlockColors.length,
      }"
    ></div>

    <div class="ml-1 mt-0.5">{{ blocksRemaining }}</div>
  </div>
</template>

<style scoped></style>

<script setup lang="ts">
import { useUserStore } from "@/stores/user";
import { useAuthStore } from "@/stores/auth";
import { useAnalyticsStore } from "@/stores/analytics";

const auth = useAuthStore();
const userStore = useUserStore();
const analyticsStore = useAnalyticsStore();

const isDev = window.location.hostname === "localhost";
</script>
<template>
  <div>
    <h1>Profile</h1>
    <br />
    <div>
      <div class="text-xs uppercase">Block Status</div>
      <p class="font-bold">
        {{ userStore.user?.membershipLabel }}
      </p>
    </div>

    <br />

    <div>
      <div class="text-xs uppercase">All Time Blocks</div>
      <p class="font-bold">
        {{ analyticsStore.allTimeBlocks }}
      </p>
    </div>

    <br /><br /><br />

    <div class="my-4 md:w-1/2">
      <p>
        Talloc is under active development and your feedback is incredibly
        valuable. It would be great to hear from you!
      </p>
      <br />
      <p class="text-blue-500 underline">
        <a href="mailto:hello@talloc.app"> hello@talloc.app</a>
      </p>
    </div>

    <!-- TODO - PULL FROM DATA SOMEWHERE -->
    <!-- 
      WHAT ELSE DO WE WANT TO SHOW HERE?
      - Email
      - Analytics?
      - Phone Number
      - Payment Status
     -->

    <div v-if="isDev">
      <p>
        <a
          :href="`https://console.firebase.google.com/u/0/project/use-talloc/firestore/data/~2Fusers~2F${userStore.uid}`"
          target="_blank"
          rel="noopener noreferrer"
          class="text-blue-500 underline"
          >User Firestore</a
        >
      </p>
    </div>

    <div class="text-left" v-if="false">
      <pre class="text-xs">{{ userStore.user }}</pre>
      <pre class="text-xs">{{ auth.user }}</pre>
    </div>
    <button
      @click="auth.signOut"
      class="mt-20 border px-2 py-1 text-xs font-bold uppercase tracking-wider text-gray-500"
    >
      Sign Out
    </button>
  </div>
</template>

<script setup lang="ts">
import { onMounted, onUnmounted } from "vue";
import SignInBlock from "@/components/SignInBlock.vue";

import { totalBlocks } from "@/globalBlocks.json";

onMounted(() => {
  document.body.classList.add("block-pattern");
});

onUnmounted(() => {
  document.body.classList.remove("block-pattern");
});
</script>
<template>
  <div class="flex min-h-screen flex-col items-center justify-center">
    <!-- TODO - ADD MARKETING BLURB -->
    <h3 class="text-center text-4xl">Sign In</h3>
    <!-- TODO - SOMETHING WITTY HERE  -->
    <!-- <p class="mt-4">There's no point if you're not seeing your progress</p> -->
    <!-- TODO - REDIRECT HOME IF ALREADY AUTHENTICATED -->
    <br />
    <div class="flex flex-col items-center">
      <!-- TODO - BETTER OUTLINE STYLE -->
      <!-- TODO - HOVER EFFECTS -->
      <SignInBlock />
    </div>
    <div class="mx-auto mt-16 flex flex-col items-center justify-center">
      <!-- <div class="text-xs uppercase">Talloc users have logged over</div> -->
      <h1 class="my-1 font-semibold">
        {{ totalBlocks.toLocaleString() }}
      </h1>
      <div class="text-xs uppercase">blocks and counting</div>
    </div>
  </div>
</template>

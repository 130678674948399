<script setup lang="ts">
import { ref } from "vue";
import { useAuthStore } from "@/stores/auth";
import { useAppStore } from "@/stores/app";
import router from "@/router";
import Icon from "@/components/Icon.vue";

const auth = useAuthStore();

const email = ref("");
const emailElement = ref(null);

// TODO - REDIRECT IF ALREADY SIGNED IN
const signInWithEmail = async () => {
  if (!email.value.length) {
    return (emailElement?.value as HTMLInputElement | null)?.focus();
  }

  if (!(emailElement.value! as HTMLInputElement).checkValidity()) {
    const app = useAppStore();
    return app.setError("OUCH! Please use a valid email address.");
  }
  try {
    await auth.signInWithEmail(email.value);
    router.push("/youve/got/mail");
  } catch (e) {
    console.error(e);
    const appStore = useAppStore();
    appStore.setError(
      "Something went wrong! A Slack notification just woke Carrie up from a nap and she's looking into it.\nPlease try again in a few moments or with a different email address."
    );
  }
};
</script>
<template>
  <!-- TODO - REDIRECT HOME IF ALREADY AUTHENTICATED -->
  <div class="flex flex-col items-center">
    <!-- TODO - BETTER OUTLINE STYLE -->
    <!-- TODO - HOVER EFFECTS -->
    <!-- TODO - REDO THE STYLING SO BUTTONS ARE DISPLAY FLEX AND ICONS/TEXT ARE NOT USING MARGIN FOR SPACING -->

    <button @click="auth.signIn('google')">
      <img src="@/assets/google.svg" class="ml-2 mr-4 h-6" alt="Google Icon" />
      Sign in with Google
    </button>

    <button @click="auth.signIn('apple')" v-if="false">
      <img src="@/assets/apple.svg" class="ml-2 mr-5 h-6" alt="Apple Icon" />
      Sign in with Apple
    </button>

    <div class="my-2 text-xs uppercase">Or</div>

    <div class="flex flex-col items-center justify-center">
      <input
        type="email"
        name="email"
        v-model="email"
        id="email"
        ref="emailElement"
        class="input-text-standard my-2 w-60 border px-2 py-0.5 text-center text-lg"
        placeholder="jim@dundermifflin.com"
        @keyup.enter="signInWithEmail"
      />
      <button @click="signInWithEmail">
        <Icon name="email" class="ml-1.5 mr-4" />
        Sign in with Email
      </button>
    </div>
  </div>
</template>
<style scoped>
button {
  @apply my-2 flex h-10 w-60 items-center border border-gray-300 bg-white px-4 py-2 text-sm font-semibold text-gray-500;
}
</style>

<script setup lang="ts">
import BlockInputGrid from "@/components/BlockInputGrid.vue";
import CategoryDetailsPanel from "@/components/CategoryDetailsPanel.vue";
import router from "@/router";
import { useUserStore } from "@/stores/user";
import { watch } from "vue";

const userStore = useUserStore();
watch(userStore, () => {
  if (userStore.initialized && userStore.isNewUser) {
    router.push("/welcome");
  }
});
</script>
<template>
  <div class="flex w-full flex-col md:flex-row">
    <div class="max-h-screen w-full md:h-auto md:w-1/2">
      <BlockInputGrid />
    </div>
    <div class="w-full md:w-1/2">
      <CategoryDetailsPanel />
    </div>
  </div>
</template>
<style scoped></style>

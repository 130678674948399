// USER-LEVEL CONFIGURATION STORE
import { defineStore } from "pinia";
import {
  arrayUnion,
  doc,
  DocumentData,
  DocumentReference,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { firestore } from "@/firebase";
import { useAuthStore } from "./auth";
import { BlockColor } from "@/types";
import { Config } from "@/objects/config";

export const useConfigStore = defineStore("config", {
  state: (): any => {
    return {
      data: [],
      numCalls: 0,
      initialized: false,
      categories: [],
      config: {},
    };
  },
  // TODO - USE THIS EVERYWHERE WITH CONVERTERS
  getters: {
    getCategoryColor: (state) => (categoryId: string) => {
      // TODO - TYPE
      const category = state.categories.find((c: any) => c.id === categoryId);
      // TODO - FREAK OUT IF THIS IS EVER UNDEFINED
      return category?.color;
    },
    getCategory: (state) => (categoryId: string) => {
      // TODO - ADD TYPE
      return state.categories.find((c: any) => c.id === categoryId);
    },
    latestConfigRef(): DocumentReference<DocumentData> {
      const userId = useAuthStore().uid;
      // TODO - ADD CONVERTER
      return doc(firestore, `users/${userId}/config/latest`);
    },
    // lists(): any[] {
    //   return this.data;
    // },
    // flowsRef(): CollectionReference<DocumentData> {
    //   return collection(firestore, "flows").withConverter(flowConverter);
    // },
  },
  actions: {
    setConfig(config: Config) {
      this.config = config;
    },
    // async writeConfig() {
    //   // TODO - HANDLE ERROR
    //   // TODO - ADD CONVERTER
    //   // TODO - SOMETHING MEANINGFUL
    //   setDoc(this.latestConfigRef, { test: Math.random() });
    // },
  },
});

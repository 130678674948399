<script setup lang="ts">
import BaseBlock from "@/components/BaseBlock.vue";
import { useDayStore } from "@/stores/day";
const { createBlock } = useDayStore();

defineProps<{
  categoryId: string;
  isQuotaBlock: boolean;
}>();
</script>

<template>
  <BaseBlock
    @click="createBlock(categoryId)"
    :isQuotaBlock="isQuotaBlock"
    :filled="false"
  />
</template>

<style scoped></style>

export const sendEvent = async (
  type: string,
  payload?: Record<string, string | number>
) => {
  // TODO - BETTER DETECTION FOR DEV/PROD/LOCAL
  // TODO - DEBUG FOR IOS APP
  if (window.location.hostname === "localhost") {
    return console.log("Analytics event", type, payload);
  }
  try {
    gtag("event", type, payload);
  } catch (e) {
    console.error(e);
  }
};

<script setup lang="ts">
import { useDayStore } from "@/stores/day";
import { useCategoryStore } from "@/stores/category";
import { BlockColor } from "@/types";
import { Block } from "@/objects/block";
import BaseBlock from "@/components/BaseBlock.vue";
import { useAppStore } from "@/stores/app";
import dayjs from "dayjs";
import { computed } from "vue";

const { removeBlock } = useDayStore();

const { block } = defineProps<{
  block: Block;
}>();

const color: BlockColor = useCategoryStore().getCategoryColor(block.categoryId);
const title = computed(() => {
  // SILLY TY- WE DONT'T NEED A GLOBAL CLOCK WHEN IT ISN"T RELATIVE
  // const appTime = useAppStore().time;
  // return dayjs(block.created.toDate()).from(appTime);
  return dayjs(block.created.toDate()).calendar();
});
</script>

<template>
  <BaseBlock :color="color" filled @click="removeBlock(block)" :title="title" />
</template>

<style scoped></style>

<script setup lang="ts">
import { onMounted, onUnmounted } from "vue";
import { storeToRefs } from "pinia";
import { useAuthStore } from "@/stores/auth";

import { BlockColor } from "@/types";

// TODO - MAKE THIS MORE DYNAMIC THAN UPDATING ON EACH DEPLOY
import { totalBlocks } from "@/globalBlocks.json";

import SignInBlock from "@/components/SignInBlock.vue";
import BaseBlock from "@/components/BaseBlock.vue";

const { initialized, isSignedIn } = storeToRefs(useAuthStore());

onMounted(() => {
  document.body.classList.add("block-pattern");
  document.querySelector("main")?.classList.add("!max-w-6xl");
});

onUnmounted(() => {
  document.body.classList.remove("block-pattern");
  document.querySelector("main")?.classList.remove("!max-w-6xl");
});

// TODO - EXPLAIN THE CONCEPT OF BLOCKS
// IMPROVED FOCUS?
// BUILD YOUR FUTURE, ONE BLOCK AT A TIME
// INCREASED INTENTIONALITY
// INCREASED ACCOUNTABILITY
// INCREASED MOTIVATION
const benefits = [
  {
    title: "Design Your Ideal Day",
    quote: "How we spend our days is, of course, how we spend our lives.",
    details:
      "Your daily actions are the cornerstone of your future. Talloc helps you build the life you want by ensuring you're spending time where it matters.",
    image: "edit.png",
    alt: "Edit your day",
    // SCREENSHOT - FULL SCREEN + CREATE CATEGORY IN SIDE PANNEL
  },
  {
    // ACCOUNTABILITY INTERPLAY
    title: "Automatic Accountability",
    quote: "What gets measured gets managed.",
    details:
      "The data doesn't lie. Talloc shines a light on how you're really spending your time. Are you really giving it your all? Or are you watching another episode on Netflix?",
    image: "analytics.png",
    alt: "Analytics for your time",
    // SCREENSHOT - HIGHLIGHT OF ANALYTICS PANE WITH TALLOC CATEGORY SELECTED
  },
  {
    title: "Your Progress Visualized",
    quote: "A journey of a thousand miles begins with a single step.",
    details:
      "Every dream begins with a single step and Talloc is here to help. Our visualization tools make your progress tangible, keeping you motivated, one block at a time.",
    image: "progress.png",
    alt: "Progress visualization",
    // https://www.jstor.org/stable/41228586
    // SCREENSHOT- TOTAL BLOCKS VIEW SO YOU CAN SEE HOW YOU BUILD OVER TIME
  },
];

// TODO - SECTIONS
const faqs = [{ question: "Who is Carrie?", response: "" }];
</script>

<template>
  <!-- NAV -->
  <nav class="-mt-8 mb-12 flex grow items-center justify-between">
    <router-link to="/" class="flex items-center">
      <img src="/favicon.svg" alt="Talloc Logo" class="mr-1 h-8" />
      <h1 class="mt-1 text-xl">Talloc</h1>
    </router-link>
    <div>
      <!-- TODO - ADD SLACK -->
      <a
        class="group"
        aria-label="Talloc on Twitter"
        href="https://twitter.com/TallocMagic"
        ><svg
          aria-hidden="true"
          class="h-6 w-6 fill-slate-500 group-hover:fill-slate-700"
        >
          <path
            d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0 0 22 5.92a8.19 8.19 0 0 1-2.357.646 4.118 4.118 0 0 0 1.804-2.27 8.224 8.224 0 0 1-2.605.996 4.107 4.107 0 0 0-6.993 3.743 11.65 11.65 0 0 1-8.457-4.287 4.106 4.106 0 0 0 1.27 5.477A4.073 4.073 0 0 1 2.8 9.713v.052a4.105 4.105 0 0 0 3.292 4.022 4.093 4.093 0 0 1-1.853.07 4.108 4.108 0 0 0 3.834 2.85A8.233 8.233 0 0 1 2 18.407a11.615 11.615 0 0 0 6.29 1.84"
          ></path></svg
      ></a>
    </div>
  </nav>
  <!-- HERO -->
  <div class="-mt-8 flex grow text-center md:mt-20">
    <div class="w-full">
      <!-- TODO - BETTER GRADIENT -->
      <h2
        class="mx-auto my-4 w-3/4 text-center text-7xl font-semibold md:text-9xl"
      >
        Your
        <span class="gradient-magic bg-clip-text text-transparent underline"
          >time matters.</span
        >
      </h2>
      <div class="mx-4 my-4 mb-8 text-base md:mx-auto md:text-xl">
        Improve the way you spend your most valuable asset with Talloc
        <!-- GET STARTED -->
        <!-- OR -->
        <!-- SEE $FAMOUS_PERSON -->
        <!-- TODO - UPDATE WITH SOMETHING MORE COMPELLING -->
        <div
          class="mt-8 flex justify-center gap-x-4 text-center text-lg font-semibold uppercase"
        >
          <router-link to="/signin" class="gradient-magic px-4 py-2 text-white"
            >Get Started</router-link
          >
          <!-- TODO - STANDARDIZE ON GRAY COLOR   -->
          <!-- TODO - ADD $PLAYER DEMO HERE -->
          <!-- TODO - MOBLE SCREENSHOT -->
          <router-link
            to="/demo"
            class="border px-4 py-2 text-gray-500 hover:border-black"
            >View Demo</router-link
          >
        </div>
      </div>
    </div>
  </div>
  <!-- OVERVIEW SCREENSHOT -->
  <section>
    <img src="@/assets/screenshot.png" alt="Talloc Screenshot" class="w-full" />
  </section>

  <!-- BLOCK DEFINITION -->
  <section class="gradient-magic -mx-[50vw] py-12 text-white md:py-20">
    <div
      class="mx-auto flex max-w-sm flex-col px-4 sm:max-w-xl md:flex-row lg:max-w-4xl"
    >
      <div class="md:w-1/2">
        <h2 class="text-left text-4xl font-bold md:text-5xl">block</h2>
        <div class="text-lg italic">[ /blɒk/ ] noun</div>
        <p class="mt-2 text-lg">
          Half-hour units into which a day is divided, serving as a strategic
          tool for structuring, visualizing, and managing time.
          <!-- <br />
          <br />
          This facilitates an effective and modular approach to time, enabling
          the design of an ideal schedule -- each block to a specific outcome
          for optimal focus and productivity. -->
        </p>
      </div>
      <div
        class="mt-8 flex flex-col items-center justify-around text-2xl font-medium md:w-1/2"
      >
        <div class="flex items-center">
          <BaseBlock :color="BlockColor.WHITE" filled class="!h-12 !w-12" />
          <span class="mx-2">=</span>
          30 minutes
        </div>
        <!-- <div class="p-4">
          <BaseBlock v-for="i in Array(48)" :color="BlockColor.WHITE" filled />
        </div> -->
      </div>
    </div>
  </section>

  <section class="!mt-12">
    <div class="mx-auto max-w-sm px-4 sm:max-w-3xl lg:max-w-4xl">
      <h2 class="mb-8 text-center text-4xl font-bold md:text-5xl">
        <!-- All the data you need to live your best life -->
        Build Your Future. <br />
        One Block at a Time.
      </h2>
      <div class="flex flex-col-reverse justify-between md:flex-row">
        <div
          class="flex flex-col justify-center gap-y-12 text-center md:text-left"
        >
          <!-- TODO - CHANGE BACKGROUND OPACITY BASED ON SELECTED/TIMING -->
          <div
            v-for="(b, i) in benefits"
            class="flex flex-col justify-between md:flex-row"
          >
            <div class="m-2 border-t p-4 text-base md:-mr-20 md:w-1/2 md:pr-20">
              <h2 class="text-2xl font-semibold">{{ b.title }}</h2>
              <p class="mt-1 text-base">{{ b.details }}</p>
            </div>
            <div class="mx-auto text-center md:w-1/2">
              <img
                :src="`/screenshots/${b.image}`"
                :alt="b.alt"
                class="mx-auto mt-8 max-h-96 border shadow-lg"
              />
            </div>
          </div>
        </div>

        <!-- IFRAME + INTERACTION INSTEAD OF SCREENSHOT? -->
      </div>
    </div>
  </section>
  <section class="!my-40">
    <!-- TODO - BETTER SOCIAL PROOF -->
    <div class="mt-12 text-center text-lg font-bold md:mt-0">
      <h2 class="text-6xl md:text-7xl">
        {{ totalBlocks.toLocaleString() }}
      </h2>
      BLOCKS AND COUNTING
    </div>
  </section>
  <div class="mx-auto my-12 max-w-sm text-center" id="getstarted">
    <h3 class="my-4 mb-12 text-5xl font-semibold">Get Started</h3>
    <SignInBlock v-if="initialized && !isSignedIn" />
  </div>
  <!-- TODO - FILL IN FOOTER -->
  <footer class="-mx-[50vw] -mb-8 bg-gray-50/10 px-[50vw] py-20"></footer>
</template>
<style scoped>
.gradient-magic {
  @apply bg-gradient-to-r from-cyan-500 to-blue-500;
}

section {
  @apply my-4 md:my-20;
}
</style>

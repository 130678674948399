import {
  BlockColor,
  BlockColorHex,
  CategoryPriority,
  DayOfWeek,
  Period,
  PeriodDays,
} from "@/types";
import { getDayOfWeek } from "@/util";
import dayjs from "dayjs";
import {
  DocumentData,
  FirestoreDataConverter,
  PartialWithFieldValue,
  QueryDocumentSnapshot,
  SetOptions,
  SnapshotOptions,
  Timestamp,
  WithFieldValue,
} from "firebase/firestore";

export class Category implements FirestoreDataConverter<Category> {
  readonly id: string;
  readonly name: string;
  readonly color: BlockColor;
  readonly quotas: Record<DayOfWeek, number>;
  readonly parentId?: string | null;
  readonly priority: CategoryPriority;
  readonly _deleted?: Timestamp | null;
  readonly _created?: Timestamp | undefined;

  constructor({
    id,
    name,
    color,
    quotas,
    parentId = null,
    priority = CategoryPriority.NONE,
    _deleted = null,
    _created,
  }: {
    id: string;
    name: string;
    color: BlockColor;
    quotas: Record<DayOfWeek, number>;
    parentId?: string | null;
    priority?: number;
    _deleted?: Timestamp | null;
    _created?: Timestamp;
  }) {
    this.id = id;
    this.name = name;
    this.color = color;
    this.quotas = quotas;
    this.parentId = parentId;
    this.priority = priority;
    this._deleted = _deleted;
    this._created = _created;
  }

  public getQuota(dayOfWeek: DayOfWeek = getDayOfWeek()): number {
    return this.quotas?.[`${dayOfWeek}`] || 0;
  }

  public getPeriodQuota(period: Period): number {
    const numDays = period === Period.ALL ? this.daysOld : PeriodDays[period];
    // I'm sure there's a better way to do this...
    let quota = 0;
    const d = dayjs();
    for (let i = 0; i < numDays; i++) {
      quota += this.getQuota(
        d.subtract(i, "days").format("dddd").toUpperCase() as DayOfWeek
      );
    }

    return quota;
  }

  get colorHex(): BlockColorHex {
    return BlockColorHex[this.color];
  }

  get daysOld(): number {
    return dayjs().diff(dayjs(this._created?.toDate()), "day");
  }

  toFirestore(modelObject: WithFieldValue<Category>): DocumentData;
  toFirestore(
    modelObject: PartialWithFieldValue<Category>,
    options: SetOptions
  ): DocumentData;
  toFirestore(
    modelObject: unknown,
    options?: unknown
  ): import("@firebase/firestore").DocumentData {
    return Category.toFirestore(modelObject as Category);
  }

  fromFirestore(
    snapshot: QueryDocumentSnapshot<DocumentData>,
    options?: SnapshotOptions | undefined
  ): Category {
    return Category.fromFirestore(snapshot, options);
  }

  static toFirestore(category: Category): any {
    throw new Error("IMPLEMENT CATEGORY TO FIRESTORE");
  }

  static fromFirestore(snapshot: any, options: any): any {
    const data = { ...snapshot.data(), id: snapshot.id };
    return new Category(data);
  }

  static getDefaultObject(): any {
    return {
      name: "",
      color: BlockColor.RED,
      quotas: {
        [DayOfWeek.MONDAY]: 0,
        [DayOfWeek.TUESDAY]: 0,
        [DayOfWeek.WEDNESDAY]: 0,
        [DayOfWeek.THURSDAY]: 0,
        [DayOfWeek.FRIDAY]: 0,
        [DayOfWeek.SATURDAY]: 0,
        [DayOfWeek.SUNDAY]: 0,
      },
    };
  }

  static generateDemoData(): Category[] {
    // TODO - IMPLEMENT ME

    return [
      new Category({
        id: Math.random() + "",
        name: "Read",
        color: BlockColor.RED,
        quotas: {
          [DayOfWeek.MONDAY]: 2,
          [DayOfWeek.TUESDAY]: 2,
          [DayOfWeek.WEDNESDAY]: 2,
          [DayOfWeek.THURSDAY]: 2,
          [DayOfWeek.FRIDAY]: 2,
          [DayOfWeek.SATURDAY]: 2,
          [DayOfWeek.SUNDAY]: 2,
        },
      }),
      new Category({
        id: Math.random() + "",
        name: "Exercise",
        color: BlockColor.GREEN,
        quotas: {
          [DayOfWeek.MONDAY]: 2,
          [DayOfWeek.TUESDAY]: 2,
          [DayOfWeek.WEDNESDAY]: 2,
          [DayOfWeek.THURSDAY]: 2,
          [DayOfWeek.FRIDAY]: 2,
          [DayOfWeek.SATURDAY]: 2,
          [DayOfWeek.SUNDAY]: 2,
        },
      }),
      new Category({
        id: "social",
        name: "Social",
        color: BlockColor.TURQUOISE,
        quotas: {
          [DayOfWeek.MONDAY]: 0,
          [DayOfWeek.TUESDAY]: 0,
          [DayOfWeek.WEDNESDAY]: 0,
          [DayOfWeek.THURSDAY]: 0,
          [DayOfWeek.FRIDAY]: 0,
          [DayOfWeek.SATURDAY]: 0,
          [DayOfWeek.SUNDAY]: 0,
        },
      }),
      new Category({
        id: Math.random() + "",
        parentId: "social",
        name: "Family",
        color: BlockColor.TURQUOISE,
        quotas: {
          [DayOfWeek.MONDAY]: 2,
          [DayOfWeek.TUESDAY]: 2,
          [DayOfWeek.WEDNESDAY]: 2,
          [DayOfWeek.THURSDAY]: 2,
          [DayOfWeek.FRIDAY]: 2,
          [DayOfWeek.SATURDAY]: 2,
          [DayOfWeek.SUNDAY]: 2,
        },
      }),
      new Category({
        id: Math.random() + "",
        parentId: "social",
        name: "Friends",
        color: BlockColor.TURQUOISE,
        quotas: {
          [DayOfWeek.MONDAY]: 2,
          [DayOfWeek.TUESDAY]: 2,
          [DayOfWeek.WEDNESDAY]: 2,
          [DayOfWeek.THURSDAY]: 2,
          [DayOfWeek.FRIDAY]: 2,
          [DayOfWeek.SATURDAY]: 2,
          [DayOfWeek.SUNDAY]: 2,
        },
      }),
      new Category({
        id: "work",
        name: "Work",
        color: BlockColor.BLACK,
        quotas: {
          [DayOfWeek.MONDAY]: 0,
          [DayOfWeek.TUESDAY]: 0,
          [DayOfWeek.WEDNESDAY]: 0,
          [DayOfWeek.THURSDAY]: 0,
          [DayOfWeek.FRIDAY]: 0,
          [DayOfWeek.SATURDAY]: 0,
          [DayOfWeek.SUNDAY]: 0,
        },
      }),
      new Category({
        id: Math.random() + "",
        parentId: "work",
        name: "Coding",
        color: BlockColor.BLACK,
        quotas: {
          [DayOfWeek.MONDAY]: 8,
          [DayOfWeek.TUESDAY]: 8,
          [DayOfWeek.WEDNESDAY]: 8,
          [DayOfWeek.THURSDAY]: 8,
          [DayOfWeek.FRIDAY]: 8,
          [DayOfWeek.SATURDAY]: 0,
          [DayOfWeek.SUNDAY]: 0,
        },
      }),

      new Category({
        id: Math.random() + "",
        parentId: "work",
        name: "Email",
        color: BlockColor.BLACK,
        quotas: {
          [DayOfWeek.MONDAY]: 1,
          [DayOfWeek.TUESDAY]: 1,
          [DayOfWeek.WEDNESDAY]: 1,
          [DayOfWeek.THURSDAY]: 1,
          [DayOfWeek.FRIDAY]: 1,
          [DayOfWeek.SATURDAY]: 0,
          [DayOfWeek.SUNDAY]: 0,
        },
      }),
      new Category({
        id: Math.random() + "",
        parentId: "work",
        name: "Meetings",
        color: BlockColor.BLACK,
        quotas: {
          [DayOfWeek.MONDAY]: 0,
          [DayOfWeek.TUESDAY]: 0,
          [DayOfWeek.WEDNESDAY]: 0,
          [DayOfWeek.THURSDAY]: 0,
          [DayOfWeek.FRIDAY]: 0,
          [DayOfWeek.SATURDAY]: 0,
          [DayOfWeek.SUNDAY]: 0,
        },
      }),
      new Category({
        id: Math.random() + "",
        name: "Meditate",
        color: BlockColor.YELLOW,
        quotas: {
          [DayOfWeek.MONDAY]: 1,
          [DayOfWeek.TUESDAY]: 1,
          [DayOfWeek.WEDNESDAY]: 1,
          [DayOfWeek.THURSDAY]: 1,
          [DayOfWeek.FRIDAY]: 1,
          [DayOfWeek.SATURDAY]: 1,
          [DayOfWeek.SUNDAY]: 1,
        },
      }),
      new Category({
        id: Math.random() + "",
        name: "Side Project",
        color: BlockColor.BLUE,
        quotas: {
          [DayOfWeek.MONDAY]: 4,
          [DayOfWeek.TUESDAY]: 4,
          [DayOfWeek.WEDNESDAY]: 4,
          [DayOfWeek.THURSDAY]: 4,
          [DayOfWeek.FRIDAY]: 4,
          [DayOfWeek.SATURDAY]: 8,
          [DayOfWeek.SUNDAY]: 8,
        },
      }),
      new Category({
        id: Math.random() + "",
        name: "Relax",
        color: BlockColor.YELLOW,
        quotas: {
          [DayOfWeek.MONDAY]: 1,
          [DayOfWeek.TUESDAY]: 1,
          [DayOfWeek.WEDNESDAY]: 1,
          [DayOfWeek.THURSDAY]: 1,
          [DayOfWeek.FRIDAY]: 1,
          [DayOfWeek.SATURDAY]: 4,
          [DayOfWeek.SUNDAY]: 4,
        },
      }),
    ];
  }
}

import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getFunctions } from "firebase/functions";

import {
  getRedirectResult,
  GoogleAuthProvider,
  AuthProvider,
  getAuth,
  initializeAuth,
  indexedDBLocalPersistence,
} from "firebase/auth";
import router from "@/router";

// const authDomain = "use-talloc.firebaseapp.com";
const authDomain = window.location.origin.includes("localhost")
  ? "use-talloc.firebaseapp.com"
  : window.location.hostname;

const firebaseConfig = {
  apiKey: "AIzaSyBkLx_Rq4GcaQYp4PQck3n0XNG8AjbMY7w",
  authDomain,
  projectId: "use-talloc",
  storageBucket: "use-talloc.appspot.com",
  messagingSenderId: "445024079652",
  appId: "1:445024079652:web:a8bd7614e31a58e802edb1",
};

const app = initializeApp(firebaseConfig);

const providers: Record<string, AuthProvider> = {
  google: new GoogleAuthProvider(),
};
const auth = getAuth();

const firestore = getFirestore();
// TODO - JUST EXPORT THE FUNCTIONS WE USE?
const functions = getFunctions(app);

// TODO - MOVE TO AUTH STORE/SOMEWHERE CLEANER
// LATER TODO - WHAT IS THIS USED FOR? AUTH?
(async function () {
  const isRedirect = await getRedirectResult(auth);
  if (isRedirect) {
    router.replace("/");
  }
})();

export { firestore, functions, auth, providers, app };
export default app;

import { defineStore } from "pinia";
import { providers, firestore, auth } from "@/firebase";
import router from "@/router";
import { useAppStore } from "@/stores/app";
import * as Sentry from "@sentry/browser";
import { setCookie } from "@/util";

import {
  signInWithRedirect,
  sendSignInLinkToEmail,
  signOut,
  onAuthStateChanged,
  User,
  isSignInWithEmailLink,
  signInWithEmailLink,
} from "firebase/auth";

import { sendEvent } from "@/analytics";
import { setDoc, doc } from "firebase/firestore";
import { useEngineStore } from "./engine";

// USED TO REDIRECT BETWEEN ASTRO and APP
const COOKIE_NAME = "active_blocker";

export const useAuthStore = defineStore("auth", {
  state: () => {
    return {
      user: null as User | null,
      initialized: false,
    };
  },
  getters: {
    // TODO - BETTER TYPES HERE - FIXING LINTER
    displayName(state): string | null | undefined {
      return state.user?.displayName || state.user?.email;
    },
    isSignedIn(state): boolean {
      return Boolean(Object.keys(state.user || {}).length);
    },
    uid(state): string | undefined {
      return state.user?.uid;
    },
  },
  actions: {
    async signIn(provider = "google") {
      // TODO - HANDLE ERRORS
      if (provider === "apple") {
        return alert(
          "Apple sign in not yet supported. Please use \"Sign in with Google\""
        );
      }
      sendEvent("signin", { provider });

      return signInWithRedirect(auth, providers[provider]);
    },
    async signInWithEmail(email: string) {
      const settings = {
        url: `${window.location.origin}/speak/friend/and/enter`,
        handleCodeInApp: true,
      };
      sendEvent("signin", { provider: "email", status: "start" });
      return sendSignInLinkToEmail(auth, email, settings)
        .then(() => {
          // The link was successfully sent. Inform the user.
          // Save the email locally so you don't need to ask the user for it again
          // if they open the link on the same device.
          window.localStorage.setItem("emailForSignIn", email);
          // ...
        })
        .catch((error: any) => {
          const errorCode = error.code;
          const errorMessage = error.message;
          console.error(errorCode, errorMessage);
          throw error;
        });
    },
    async finishEmailSignIn() {
      if (isSignInWithEmailLink(auth, window.location.href)) {
        // Get the email if available. This should be available if the user completes
        // the flow on the same device where they started it.
        let email = window.localStorage.getItem("emailForSignIn");
        if (!email) {
          // User opened the link on a different device. To prevent session fixation
          // attacks, ask the user to provide the associated email again. For example:
          email = window.prompt("Please provide your email for confirmation");
        }
        sendEvent("signin", { provider: "email", status: "finish" });
        // The client SDK will parse the code from the link for you.
        signInWithEmailLink(auth, email!, window.location.href)
          .then((result) => {
            // Clear email from storage.
            window.localStorage.removeItem("emailForSignIn");
            router.replace("/");
          })
          .catch((error) => {
            console.error(error);
            const appStore = useAppStore();
            appStore.setError(
              "Shoot! Something went wrong again and we were unable to sign you in. We've paged our engineers but in the meantime, please try again."
            );
          });
      }
    },
    async signOut() {
      await signOut(auth);
      sendEvent("signout");
      setCookie(COOKIE_NAME, "", -1);
      window.location.href = "/";
    },
    async setUser(user: User | null) {
      this.user = user;
      useEngineStore().createEngine(user?.uid);
      if (user) {
        // TODO - MOVE THIS?
        setCookie(COOKIE_NAME, "1", 90);
        // TODO - MOVE TO ENGINE OR USER STORE?
        await setDoc(
          doc(firestore, `users/${user.uid}`),
          {
            _lastSeen: new Date(),
          },
          { merge: true }
        );
        Sentry.setUser({ emai: user?.email, id: user?.uid });
      } else {
        Sentry.setUser(null);
      }
    },
    async isInitialized(): Promise<boolean> {
      if (this.initialized) {
        return true;
      }

      return new Promise((resolve, reject) => {
        const unsubscribe = onAuthStateChanged(
          auth,
          async (user) => {
            this.initialized = true;
            this.setUser(user);
            resolve(true);
          },
          // TODO - SEND TO SENTRY AND DISPLAY ERROR
          (e) => {
            console.error(e);
            reject(e);
          }
        );
      });
    },
  },
});

<script setup lang="ts">
import BaseBlock from "@/components/BaseBlock.vue";
import { Block } from "@/objects/block";
import { useAnalyticsStore } from "@/stores/analytics";
import { useAppStore } from "@/stores/app";
import { useCategoryStore } from "@/stores/category";
import { useDayStore } from "@/stores/day";
import { BlockColor } from "@/types";
import dayjs, { Dayjs } from "dayjs";
import { storeToRefs } from "pinia";
import { computed, ref } from "vue";

const analytics = useAnalyticsStore();

const { time } = storeToRefs(useAppStore());

const SHOW_ALL_OFFSET = 8;

const { blocksToday } = storeToRefs(analytics);

const events = computed(() => {
  const blocks = useDayStore().getDayBlocks();
  const { getCategory } = useCategoryStore();
  const events = blocks
    .map((block: Block) => ({
      ts: dayjs(block.created.toDate()),
      category: getCategory(block.categoryId),
      // color: getCategoryColor(block.categoryId),
    }))
    .reverse();

  return showAll.value
    ? events
    : events.filter(
        (e: any) => time.value.diff(e.ts, "hour") < SHOW_ALL_OFFSET
      );
});

const getSpacerHeight = (
  older: Dayjs = dayjs().hour(6),
  newer: Dayjs = dayjs()
) => {
  const diff = newer.diff(older, "minute");
  return diff > 0 ? `${diff}px` : "0px";
};

const getDiffText = (
  older: Dayjs = dayjs().hour(6),
  newer: Dayjs = dayjs()
) => {
  return newer.diff(older, "minute") > 10 ? newer.from(older, true) : "";
};

const showAll = ref(false);

const showingAllBlocks = computed(() => {
  return showAll.value && events.value.length === blocksToday.value;
});
</script>

<template>
  <!-- TODO - ADD REALTIME ASSOCIATED EFFICIENCY WITH EACH BLOCK ADDED? -->
  <!-- TODO - START BLOCK FOR DAY? -->
  <!-- TODO - ADD CUTOFF AND EXPAND TOGGLES -->
  <div class="flex flex-col text-left text-xs">
    <!-- TODO - MOVE THIS INTO THE EVENTS CREATION -->
    <div class="flex items-center">
      <BaseBlock :color="BlockColor.WHITE" class="!h-2.5 !w-2.5" />
      <div class="ml-1 w-14 text-[0.6rem]">
        {{ time.format("LT") }}
      </div>
      <div>Now</div>
    </div>
    <div
      class="ml-2 flex items-center border-l pl-3 text-[0.6rem] text-gray-400"
      :style="`height: ${getSpacerHeight(events[0]?.ts, time)}`"
    >
      {{ getDiffText(events[0]?.ts, time) }}
    </div>

    <template v-for="(event, index) in events" :key="event">
      <div class="flex items-center">
        <BaseBlock
          :color="event.category.color"
          class="!h-2.5 !w-2.5 min-w-max border-white"
          filled
        />
        <div class="ml-1 w-14 min-w-max text-[0.6rem]">
          {{ event.ts.format("LT") }}
        </div>
        <!-- TODO - FIX THIS BUG WITH REALLY LONG NAMES -->
        <div class="max-w-[5rem] truncate">
          {{ event.category.name }}
        </div>
      </div>
      <!-- TODO - ANCHOR THIS TO A START TIME -->
      <div
        v-if="index < events.length - 1"
        class="ml-2 flex items-center border-l pl-3 text-[0.6rem] text-gray-400"
        :style="`height: ${getSpacerHeight(events[index + 1]?.ts, event.ts)}`"
      >
        {{ getDiffText(events[index + 1]?.ts, event.ts) }}
      </div>
    </template>

    <div>
      <button
        class="mt-2 text-[0.6rem] italic text-gray-400"
        @click="showAll = !showAll"
      >
        {{ showAll ? `Show last ${SHOW_ALL_OFFSET} hours` : "Show all" }}
      </button>
    </div>
  </div>
</template>

<style scoped></style>

import { defineStore } from "pinia";

import { Engine } from "@/objects/engine";
import { useUserStore } from "@/stores/user";

import { useCategoryStore } from "@/stores/category";
import { useDayStore } from "@/stores/day";
import { useConfigStore } from "@/stores/config";
import { useAnalyticsStore } from "./analytics";

export const useEngineStore = defineStore("engine", {
  state: (): { engine: Engine | undefined } => {
    return {
      engine: undefined,
    };
  },
  actions: {
    createEngine(uid?: string) {
      console.log("Creating Engine...", uid);
      if (!uid) {
        this.resetEngine();
        return console.log("Clearing Engine...");
        // DO WE NEED TO CLEAR THE OTHER STORES?
      }

      if (this.engine?.uid === uid) {
        return console.log(
          "Engine already exists for this user... Returning..."
        );
      }

      this.engine = new Engine(uid, {
        user: useUserStore().setUser,
        categories: (categories) => {
          useCategoryStore().setCategories(categories);
          useAnalyticsStore().setCategories(categories);
        },
        days: (days) => {
          useDayStore().setDays(days);
          useAnalyticsStore().setDays(days);
        },
        config: useConfigStore().setConfig,
        analyticsTotals: useAnalyticsStore().setTotals,
        analyticsRecents: useAnalyticsStore().setRecents,
      });
    },
    resetEngine() {
      this.engine?.detachSnapshotListeners();
      this.engine = undefined;
    },
  },
});

<script setup lang="ts">
import { useUserStore } from "@/stores/user";
import router from "@/router";
import BaseBlock from "@/components/BaseBlock.vue";
import { WizardStep, WizardStepType } from "@/types";
import { sendEvent } from "@/analytics";
import { BlockColor } from "@/types";

const userStore = useUserStore();

const steps: WizardStep[] = [
  {
    text: "What should we call you?",
    type: WizardStepType.TEXT_QUESTION,
    required: true,
    actions: [
      {
        label: "Next",
        callback: (text: string) => {
          userStore.setUserProperty("name", text);
        },
      },
    ],
  },
  // {
  //   text: "Is your partner already using Lantern?",
  //   type: WizardStepType.BUTTON_QUESTION,
  //   required: true,
  //   actions: [
  //     {
  //       label: "Yes, please link our accounts!",
  //       callback: async () => {
  //         await userStore.setUserProperty(
  //           "milestones.welcomeCompleted",
  //           new Date()
  //         );
  //         setTimeout(() => {
  //           router.push("/link");
  //         }, 0);
  //       },
  //     },
  //     {
  //       label: "Not yet, we're just getting started!",
  //       callback: async () => {
  //         // TODO - BETTER STRUCTURE
  //         await userStore.setUserProperty(
  //           "milestones.welcomeCompleted",
  //           new Date()
  //         );
  //         // HACK - REMOVE THIS ONCE WE FIX
  //         setTimeout(() => {
  //           router.push("/relationship/new");
  //         }, 0);
  //       },
  //     },
  //   ],
  //   filter: () => {
  //     return !Boolean(secret);
  //   },
  // },
  // {
  //   text: "It looks like you're joining an existing relationship! Would you like to proceed?",
  //   type: WizardStepType.BUTTON_QUESTION,
  //   actions: [
  //     {
  //       label: "Yes, please link our accounts!",
  //       callback: async () => {
  //         await userStore.setUserProperty(
  //           "milestones.welcomeCompleted",
  //           new Date()
  //         );
  //         // HACK - REMOVE THIS ONCE WE FIX
  //         setTimeout(() => {
  //           router.push(`/link/${secret}`);
  //         }, 0);
  //       },
  //     },
  //     {
  //       label: "Not yet, let me look around first",
  //       callback: async () => {
  //         // TODO - BETTER STRUCTURE
  //         await userStore.setUserProperty(
  //           "milestones.welcomeCompleted",
  //           new Date()
  //         );
  //         // HACK - REMOVE THIS ONCE WE FIX
  //         setTimeout(() => {
  //           router.push("/relationship/new");
  //         }, 0);
  //       },
  //     },
  //   ],
  //   filter: () => {
  //     return Boolean(secret);
  //   },
  // },
];
// ].filter((step) => {
//   return step.filter ? step.filter() : true;
// });

const onComplete = async () => {
  userStore.setUserProperty("milestones.welcomeCompleted", new Date());
  if (userStore.isNewUser) {
    sendEvent("milestone-welcome");
  }
  router.push("/category/new");
};
</script>
<template>
  <div class="mx-auto max-w-lg">
    <!-- TODO - INTERsPERSE IMAGES -->
    <h2 class="mt-4">Welcome to Talloc</h2>
    <p>
      Calendars and TODO lists are great, but for most people, they capture what
      the day <i>should have looked like</i> rather than what it
      <i>did look like</i>.
    </p>
    <p>
      This presents us with a data problem for the single most valuable resource
      in our world -- our time.
    </p>
    <p>
      Talloc serves as a personalized attention distribution platform that gives
      you the data you need to ensure you're spending your time on the things
      that matter most.
    </p>

    <h3>Blocks</h3>
    <p>
      Each block represents half an hour of activity, capturing a distinct
      segment of your day.
    </p>
    <div class="flex items-center justify-center text-center text-xl">
      <BaseBlock :color="BlockColor.BLUE" filled class="!h-6 !w-6" />
      = 30 minutes
    </div>
    <h3>Days</h3>
    <p>
      Assuming you sleep for 8 hours, each day consists of 32 blocks. Each day
      in Talloc starts at 6am and ends at 10pm. Future versions will allow you
      to personalize these times.
    </p>
    <div class="text-center"><BaseBlock v-for="i in Array(32)" /></div>
    <h3>Categories</h3>
    <p>
      Each block is assigned to a category, which represents a type of activity
      or task. Each category can be prioritized based on how important it is to
      you.
    </p>
    <p>
      For example, you might say it's absolutely critical to
      <BaseBlock :color="BlockColor.RED" filled /><span
        class="font-bold text-red-500"
        >Read</span
      >
      for an hour (2 blocks) everyday while it's a low priority to go through
      your
      <BaseBlock :color="BlockColor.DARKGRAY" filled /><span
        class="font-bold text-gray-500"
        >Inbox</span
      >
      for 30 minutes (1 block).
    </p>
    <h3>Analytics</h3>
    <p>
      As you record how you're allocating your time, a number of different
      metrics are generated to give you insight into your overall performance.
    </p>
    <p>
      Are you spending time on what genuinely matters to you? Is your day being
      eaten up by low priority tasks? Are you spending too much time on
      <BaseBlock :color="BlockColor.BLACK" filled /><span
        class="font-bold text-black"
        >Work</span
      >
      and not enough with your
      <BaseBlock :color="BlockColor.TURQUOISE" filled /><span
        class="font-bold text-teal-500"
        >Family</span
      >?
    </p>
    <p>
      You'll soon be able to answer these questions with hard data -- not just a
      gut feeling.
    </p>
    <h3>Beta</h3>
    <p>
      Talloc is gradually being converted from a personal tool hacked together
      over many years to something useful to everyone <i>still</i> looking for a
      time allocation tool that resonates with them. It's not for everyone, but
      it might be for you.
    </p>
    <p>Let us know what you think!</p>
    <p>
      <a href="mailto:hello@talloc.app" class="text-blue-500 underline"
        >hello@talloc.app</a
      >
    </p>
    <br />
    <button @click="onComplete" class="btn-sm btn">Get Started</button>
  </div>
  <!-- <Wizard title="Welcome to Talloc!" :steps="steps" :onComplete="onComplete" /> -->
</template>
<style scoped>
p {
  @apply my-8;
}

h3 + p {
  @apply mt-4;
}

h3 {
  @apply mt-12 font-semibold;
}
</style>

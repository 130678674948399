<script setup lang="ts">
// SHOULD BE ASTRO

const throwError = () => {
  throw new Error("Debug Page Throw");
};

const consoleError = () => {
  console.error("Debug Page console.error");
};
</script>

<template>
  <div>
    <h1 class="mt-4">Debug</h1>
    <button @click="throwError">Trigger Sentry - Throw New Error</button>
    <button @click="consoleError">Trigger Sentry - console.error</button>
  </div>
</template>
<style scoped>
button {
  @apply m-4 bg-black p-2 font-semibold text-white;
}
</style>

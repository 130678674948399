<script setup lang="ts">
import BaseBlock from "@/components/BaseBlock.vue";
import StatsStack from "@/components/StatsStack.vue";
import { Block } from "@/objects/block";
import { useAnalyticsStore } from "@/stores/analytics";
import { useAppStore } from "@/stores/app";
import { useCategoryStore } from "@/stores/category";
import { useDayStore } from "@/stores/day";
import dayjs, { Dayjs } from "dayjs";
import { storeToRefs } from "pinia";
import { computed, ref } from "vue";
import { CategoryPriorityLabels, Period } from "@/types";
import { toPercentageString } from "@/util";

const analytics = useAnalyticsStore();

const { time } = storeToRefs(useAppStore());

const { activeCategory } = storeToRefs(useCategoryStore());

const timeSinceLastBlock = computed(() => {
  const blocks = useDayStore().getDayBlocks();
  const lastBlock = blocks
    .filter((b: Block) => b.categoryId === activeCategory.value.id)
    .at(-1);

  if (!lastBlock) {
    return "No blocks today"; // TODO - FUNNY MESSAGE
  }

  const min = dayjs.min(time.value, dayjs(lastBlock.created.toDate()));
  return `Last block added ${min.from(time.value)}`;
});

const weekBlockCounts = computed(() => {
  const blocks = useDayStore().getCategoryWeekBlockCounts(
    activeCategory.value.id
  );
  return blocks;
});

const recentBlockCounts = computed(() => {
  const summary = analytics.getRecentBlockSummary(activeCategory.value.id);

  // QUESTIONS
  // DELTAAS IN BLOCKS OR PERCENTAGE?
  // DELATAS RELATIVE TO WHAT?
  // - DAY?
  // - PREVIOUS TIMEFRAME?

  // TODO - HOW DO WE WANT TO DISPLAY THIS?
  // - TABLE + COLUMNS AND SIDE LABELS?

  // TODO - INCLUDE QUOTA IN SUMMARY
  // TODO - MOVE THIS TO ANALYTICS STORE

  return [
    {
      label: "Total",
      value: summary.total,
    },
    {
      label: "Quarter",
      value: summary.quarter,
      average: summary.quarter / 90,
    },
    {
      label: "Month",
      value: summary.month,
      average: summary.month / 30,
      // TODO - FIX DELTAS
      delta: summary.month / 30 - summary.quarter / 90,
    },
    {
      label: "Week",
      value: summary.week,
      average: summary.week / 7,
      delta: summary.week / 7 - summary.month / 30,
    },
    {
      label: "Day",
      value: summary.day,
      average: summary.day,
      delta: summary.day - summary.week / 7,
    },
  ].reverse();
});

const metrics = computed(() => {
  const numBlocks = weekBlockCounts.value.reduce(
    (a: any, b: any) => a + b.count,
    0
  );
  const quota = weekBlockCounts.value.reduce(
    (a: any, b: any) => a + b.quota,
    0
  );

  const monthQuota = activeCategory.value.getPeriodQuota(Period.MONTH);

  const summary = analytics.getRecentBlockSummary(activeCategory.value.id);

  return [
    {
      label: "Blocks",
      week: numBlocks,
      month: summary.month,
    },
    {
      label: "Average",
      week: (numBlocks / 7).toLocaleString("en-US", {
        maximumFractionDigits: 1,
      }),
      month: (summary.month / 30).toLocaleString("en-US", {
        maximumFractionDigits: 1,
      }),
    },
    {
      label: "Quota",
      // CHANGE TO SHOW RATIO?
      week: `${numBlocks}/${quota}`,
      month: `${summary.month} / ${monthQuota}`,
    },
    {
      label: "Score",
      tip: "Quota performance expressed as a percentage",
      // TODO - ACCOUNT FOR CHANGING QUOTAS IN THE PAST
      week: toPercentageString(numBlocks / quota),
      month: toPercentageString(summary.month / monthQuota),
    },
    // TODO - PERCENTAGE OF TOTAL BLOCKS
    // TODO - DIFF FROM PREVIOUS WEEK
    // TODO - ALLTIME
  ];
});

const monthBlockCounts = computed(() => {
  const blocks = analytics.getCategoryMonthBlocks(activeCategory.value.id);

  // GROSS - DO THIS RIGHT
  // update blocks to include the quota based on the day of the week
  return blocks.map((b: any) => {
    return {
      ...b,
      quota: activeCategory.value.quotas[b.day.format("dddd").toUpperCase()],
    };
  });
});

const lastBlockTimestamp = computed(() => {
  const blocks = useDayStore().getDayBlocks();
  const lastBlock: Block = blocks
    .filter((b: Block) => b.categoryId === activeCategory.value.id)
    .at(-1);

  if (!lastBlock) {
    return null;
  }

  return dayjs(lastBlock.created.toDate()).format("h:mm A");
});
</script>

<template>
  <!-- TODO - PIVOT THIS WITH WEEK DETAILS SO YOU SEE RUNNING OR CATEGORY DETAILS -->
  <div
    class="flex flex-col text-left text-xs"
    :key="activeCategory"
    v-if="activeCategory"
  >
    <div class="-ml-1 flex items-center">
      <BaseBlock filled :color="activeCategory.color" class="!h-9 !w-9" />
      <div class="ml-0.5 flex grow flex-col">
        <!-- TODO - FIX THIS WITH GRID -->
        <h5 class="-mt-[1px] flex items-center truncate md:max-w-[12rem]">
          {{ activeCategory.name }}
          <router-link
            :to="`/category/${activeCategory.id}/edit`"
            class="ml-auto text-xs uppercase text-gray-300 hover:text-gray-400"
            >Edit</router-link
          >
        </h5>
        <div class="-mt-0.5 text-xs">
          {{ timeSinceLastBlock }}
          <span v-if="lastBlockTimestamp" class="md:hidden">
            at {{ lastBlockTimestamp }}</span
          >
        </div>
      </div>
    </div>
    <!-- TODO - FINISH ADDING PRIORITY -->
    <!-- <div class="text-left">
      <span
        class="rounded bg-red-500 p-0.5 px-1 text-center text-[0.6rem] font-semibold text-black text-white"
      >
        {{ CategoryPriorityLabels[activeCategory.priority] }}
      </span>
    </div> -->

    <StatsStack class="mb-4 mt-2 md:my-4 md:mr-4" />

    <div :key="weekBlockCounts" class="mt-2 hidden md:block">
      <h6 class="text-xs text-gray-400">Past 7 Days</h6>
      <div class="mt-2 flex">
        <div class="hidden w-2/3 items-end justify-around md:flex">
          <div v-for="d in weekBlockCounts" class="text-center">
            <div class="flex flex-col-reverse">
              <BaseBlock
                v-for="i in Math.max(d.count || 0, d.quota || 1)"
                :filled="i <= d.count"
                :color="activeCategory.color"
                class="!h-3.5 !w-3.5 cursor-default"
                :class="d.quota === 0 && d.count === 0 ? 'opacity-0' : ''"
              />
            </div>
            <!-- TODO - ADD HOVER TO SHOW DATE -->
            <!-- <div class="text-xs text-gray-400">{{ d.count }}/{{ d.quota }}</div> -->
            <div class="text-[0.6rem] text-gray-400">
              {{ d.date.format("dd") }}
            </div>
          </div>
        </div>

        <div
          class="flex grow cursor-default justify-around text-center md:w-1/3 md:flex-col md:gap-y-4"
        >
          <div v-for="metric in metrics">
            <div>
              {{ metric.week }}
            </div>
            <div class="tooltip tooltip-bottom" :data-tip="metric.tip">
              <div class="metric-label">
                {{ metric.label }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- TODO - HOVER STATES -->
    <!-- TODO - REFACTOR -->
    <div :key="monthBlockCounts" class="mt-8 hidden md:block">
      <h6 class="text-xs text-gray-400">Past 30 Days</h6>
      <div class="mt-2">
        <div class="flex items-end justify-start">
          <div v-for="d in monthBlockCounts" class="text-center">
            <div class="flex flex-col-reverse">
              <BaseBlock
                v-for="i in Math.max(d.count || 0, d.quota || 1)"
                :filled="i <= d.count"
                :color="activeCategory.color"
                class="!m-[1px] !h-1.5 !w-1.5 cursor-default !border-0"
                :class="{
                  'opacity-0': d.quota === 0 && d.count === 0,
                  'bg-gray-100': i > d.count,
                }"
              />
            </div>
            <!-- TODO - ADD HOVER TO SHOW DATE -->
            <!-- <div class="text-xs text-gray-400">{{ d.count }}/{{ d.quota }}</div> -->
            <!-- <div class="text-[0.6rem] text-gray-400">
              {{ d.day.format("dd") }}
            </div> -->
          </div>
        </div>

        <div class="mr-4 mt-4 flex cursor-default justify-between text-center">
          <div v-for="metric in metrics">
            <div>
              {{ metric.month }}
            </div>
            <div class="tooltip tooltip-bottom" :data-tip="metric.tip">
              <div class="metric-label">
                {{ metric.label }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- TODO - FIGURE OUT HOW WE WANT TO SHOW THIS -->
    <template v-if="false">
      <h6 class="mt-4 text-xs text-gray-400">Averages</h6>
      <div
        :key="weekBlockCounts"
        class="mb-2 mr-4 flex items-start justify-between py-2 text-center"
      >
        <div v-for="data in recentBlockCounts" class="flex flex-col">
          {{
            data?.average?.toLocaleString(undefined, {
              maximumFractionDigits: 1,
            }) || "-"
          }}
          <!-- TODO - SHOW IMPACT TODAY OR RELATIVE TO LAST TIME? -->
          <div
            class="my-1 text-xs font-medium"
            :class="(data?.delta || 0) > 0 ? 'text-green-500' : 'text-red-500'"
          >
            {{ (data?.delta || 0) > 0 ? "+" : ""
            }}{{
              data?.delta?.toLocaleString(undefined, {
                maximumFractionDigits: 1,
              }) || "-"
            }}
          </div>
          <span class="metric-label">{{ data.label }}</span>
        </div>
      </div>
    </template>
  </div>
  <div v-else>
    <!-- TODO - ADD GLOBAL METRICS AS DEFAULTS -->
    <h5 class="text-left text-xs">No category selected</h5>
  </div>
</template>

<style scoped></style>

<script setup lang="ts">
import { useAnalyticsStore } from "@/stores/analytics";
import { storeToRefs } from "pinia";
import { computed, ref } from "vue";
import BlockDayLine from "@/components/BlockDayLine.vue";
import DayTimeline from "@/components/DayTimeline.vue";
import CategoryDetails from "@/components/CategoryDetails.vue";
import { useCategoryStore } from "@/stores/category";

/*

START WITH BASICS AND THEN EXPAND
=================================
- Blocks Completed Today
- Blocks Remaining Today
- % of Blocks Completed Today
- % day efficiency
- % running efficiency
- blocks remaining today
- Category Momentum

// CLEAN UI
// SHOW TIMING OF RECORDED BLOCKS?
// TIMER OF TIME SINCE LAST BLOCK?
// SHOW PERCENTAGE BREAKDOWNS?

// TODO - MONTH VIEW

TRENDS
REALTIME LAST N DAYS
TOTALS
CATEGORY BREAKDOWN
BREAKDOWN BY DAY/TIME/UNDER/OVER QUOTA

*/

const analytics = useAnalyticsStore();

const {
  blocksToday,
  quotaBlocksToday,
  quotaBlocksCompleted,
  timeSinceLastBlock,
  runningEfficiency,
  dayEfficiency,
} = storeToRefs(analytics);

const { activeCategory } = storeToRefs(useCategoryStore());
const { setActiveCategoryId } = useCategoryStore();
// TODO - EVENTUALLY PULL THESE FROM USER SETTINGS
// TODO - ADD LINKS TO PAGES THAT EXPLAIN WHAT EACH OF THESE ARE
const metrics = computed(() => [
  {
    label: "Blocks",
    DAY: blocksToday.value,
    // WEEK: blocksToday.value,
    WEEK: "TODO",
  },
  {
    label: "Quota",
    DAY: `${quotaBlocksCompleted.value}/${quotaBlocksToday.value}`,
    // WEEK: `${quotaBlocksCompleted.value}/${quotaBlocksToday.value}`,
    WEEK: "TODO",
  },
  {
    label: "Running",
    tip: '"Running Efficiency" is the percentage of blocks recorded so far of the total blocks that could have been recorded by this point in the day.',
    DAY: runningEfficiency.value,
    // WEEK: runningEfficiency.value,
    WEEK: "TODO",
  },
  {
    label: "Total",
    tip: '"Total Efficiency" is the percentage of the total blocks recorded out of the possible blocks that could be recorded in a full day.',
    DAY: dayEfficiency.value,
    // WEEK: dayEfficiency.value,
    WEEK: "TODO",
  },
]);

const timeScales = ["DAY" /*"WEEK" /*"MONTH", "YEAR", "ALL" */];
// TODO - PULL FROM USER PREFERENCES
const activeScale = ref("DAY");
</script>
<template>
  <!-- TODO - Allow users to customize this panel -->
  <!-- TODO - CLICK TO TOGGLE TIME DURATION DAY/WEEK/MONTH/YEAR -->
  <div class="fixed inset-x-0 bottom-0 text-center md:static">
    <!-- MOBILE VIEW -->
    <div class="border-t bg-white p-2 pb-3 md:hidden">
      <div v-if="activeCategory" class="-mt-1 flex flex-col">
        <button
          @click="setActiveCategoryId('')"
          class="float-left flex items-center text-[0.6rem] text-gray-400"
        >
          <Icon name="chevron_left" class="text-xs" />
          BACK
        </button>
        <CategoryDetails class="mx-1" />
      </div>
      <template v-else>
        <div class="flex justify-between">
          <!-- TODO - PROGRESS BARS? -->
          <!-- TODO - TAP TO TOGGLE DISPLAYS/TIMESCALES -->
          <!-- OR - TAP TO BLOW UP PANEL WITH MORE DETAILED STATS? -->
          <div v-for="metric in metrics" class="w-20">
            <div class="text-base">{{ (metric as any)[activeScale] }}</div>
            <div class="text-xs text-gray-400">{{ metric.label }}</div>
          </div>
        </div>
        <BlockDayLine />
      </template>
    </div>

    <!-- DESKTOP VIEW -->
    <div class="hidden pl-2 md:block">
      <!-- TODO - COMPONENT -->
      <div class="text-left text-xs text-gray-400">
        Analytics - Today
        <button
          v-if="false"
          v-for="scale in timeScales"
          @click="activeScale = scale"
          class="mx-1 text-sm"
          :class="{
            'text-gray-400': scale !== activeScale,
            // 'bg-[#3498db] font-semibold text-black text-white':
            // 'font-medium text-black': scale === activeScale,
            'capitalize text-black': scale === activeScale,
          }"
        >
          {{ scale.toLocaleLowerCase() }}
        </button>
      </div>
      <div class="mx-1 mb-4 flex cursor-default justify-between pt-2">
        <div v-for="metric in metrics">
          <div class="text-base">{{ (metric as any)[activeScale] }}</div>
          <div class="tooltip tooltip-bottom" :data-tip="metric.tip">
            <div class="metric-label">
              {{ metric.label }}
            </div>
          </div>
        </div>
      </div>

      <!-- <BlockDayLine /> -->

      <!-- <div class="mt-4 text-xs uppercase">
        Last block recorded
        {{ timeSinceLastBlock }}
      </div> -->

      <div class="mt-4 flex justify-between border-t border-gray-100 pt-4">
        <div class="w-3/5">
          <h6 class="mb-2 text-left text-xs text-gray-400">Category</h6>
          <CategoryDetails />
        </div>
        <div class="w-2/5 border-l border-gray-100 pl-4">
          <h6 class="mb-2 text-left text-xs text-gray-400">Timeline</h6>
          <DayTimeline />
        </div>
      </div>

      <!-- <h6 class="mb-2 border-b border-l pl-2 text-left">WEEK</h6>
      <div class="flex justify-center">
        <div v-for="metric in metrics" class="w-20">
          <div class="text-base">{{ metric.value }}</div>
          <div class="text-xs uppercase text-gray-500">{{ metric.label }}</div>
        </div>
      </div> -->

      <!-- TODO - PROGRESS BAR SHOWIHG PREVIOUS DAY AND LAST WEEK, ETC -->
      <!-- LIST NEXT MOST IMPORTANT BLOCKS TO TACKLE -->
      <!-- +/- DAILY/MONTHLY IMPACT -->
      <!-- DELTA FROM EXPECTED -->
    </div>
  </div>
</template>

<style scoped></style>

import { createRouter, createWebHistory } from "vue-router";

// import About from "@/views/About.vue";
import FinishEmailSignIn from "@/views/FinishEmailSignIn.vue";
import Home from "@/views/Home.vue";
// import Privacy from "@/views/Privacy.vue";
import Profile from "@/views/Profile.vue";
// import Explore from "@/views/Explore.vue";
import SignIn from "@/views/SignIn.vue";
import YouveGotMail from "@/views/YouveGotMail.vue";
import NotFound from "@/views/NotFound.vue";
import Welcome from "@/views/Welcome.vue";
import CategoryNew from "@/views/CategoryNew.vue";
import CategoryEdit from "@/views/CategoryEdit.vue";
import Help from "@/views/Help.vue";
import Debug from "@/views/Debug.vue";
import Demo from "@/views/Demo.vue";
import { useAuthStore } from "@/stores/auth";
import { useAppStore } from "@/stores/app";
import { sendEvent } from "@/analytics";

// TODO - TITLE ATTRIBUTES FOR META ON EACH ROUTE?
const routes = [
  {
    name: "Home",
    path: "/",
    component: Home,
    meta: {
      public: true,
    },
  },
  {
    name: "CategoryNew",
    path: "/category/new",
    component: CategoryNew,
    meta: {
      title: "New Category",
    },
  },
  {
    name: "CategoryEdit",
    path: "/category/:id/edit",
    component: CategoryEdit,
    props: true,
    meta: {
      title: "Edit Category",
    },
  },
  // {
  //   path: "/explore/:id?",
  //   component: Explore,
  // },
  // { path: "/about", component: About, meta: { public: true } },
  {
    name: "SignIn",
    path: "/signin",
    component: SignIn,
    meta: {
      public: true,
      title: "Sign In",
    },
  },
  {
    path: "/speak/friend/and/enter",
    component: FinishEmailSignIn,
    meta: {
      public: true,
      title: "Speak Friend and Enter",
    },
  },
  {
    path: "/youve/got/mail",
    component: YouveGotMail,
    meta: {
      public: true,
      title: "You've Got Mail",
    },
  },
  {
    path: "/profile",
    component: Profile,
    meta: {
      title: "Profile",
    },
  },
  // {
  //   path: "/privacy",
  //   component: Privacy,
  //   meta: {
  //     public: true,
  //   },
  // },
  // {
  //   path: "/admin",
  //   component: Admin,
  //   // import.meta.env.MODE !== "admin"
  //   //   ? Home
  //   //   : defineAsyncComponent({
  //   //       loader: () => import("@/views/Admin.vue"),
  //   //       // TODO - ACTUAL SKELETON/LOADING COMPONENT
  //   //       loadingComponent: Home,
  //   //     }),
  //   meta: {
  //     wide: true,
  //   },
  // },
  {
    name: "Welcome",
    path: "/welcome",
    component: Welcome,
    meta: {
      title: "Welcome",
    },
  },
  {
    name: "Help",
    path: "/help",
    component: Help,
    meta: {
      title: "Help",
    },
  },
  {
    name: "Demo",
    // Add params for specific demo
    path: "/demo",
    component: Demo,
    meta: {
      public: true,
      title: "Demo",
    },
  },
  // TODO - HIDE FOR EVERYONE BUT TC
  {
    name: "Debug",
    path: "/debug",
    component: Debug,
    meta: {
      title: "Debug",
    },
  },

  // _  _    ___  _  _
  // | || |  / _ \| || |
  // | || |_| | | | || |_
  // |__   _| |_| |__   _|
  //    |_|  \___/   |_|
  {
    name: "NotFound",
    path: "/:pathMatch(.*)*",
    component: NotFound,
    meta: {
      title: "Not Found",
    },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(async (to, from, next) => {
  const auth = useAuthStore();
  await auth.isInitialized();
  useAppStore().setNavTitle();

  window.scrollTo(0, 0);

  const title = to?.meta?.title
    ? `${to.meta.title} | Talloc`
    : "Talloc | Time Allocated";
  useAppStore().setNavTitle(title);

  if (to.meta.public) {
    return next();
  }

  if (auth.isSignedIn) {
    if (
      from.name === "SignIn" &&
      from.query.redirect &&
      to.fullPath !== from.query.redirect
    ) {
      return next(from.query.redirect as string);
    }
    return next();
  }

  return next({ name: "SignIn", query: { redirect: to.fullPath } });
});

router.afterEach((to, from) => {
  sendEvent("page_view");
});

export default router;

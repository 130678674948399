<script setup lang="ts">
import { storeToRefs } from "pinia";
import { useCategoryStore } from "@/stores/category";
import { useUserStore } from "@/stores/user";

import { BlockColor } from "@/types";

import CategoryBlockRow from "@/components/CategoryBlockRow.vue";
import DateMenu from "@/components/DateMenu.vue";
import BaseBlock from "@/components/BaseBlock.vue";
import { ref, computed } from "vue";
import { useDayStore } from "@/stores/day";
import { useAppStore } from "@/stores/app";

const { parentCategories, getSubCategories } = storeToRefs(useCategoryStore());
const { hasFirstBlock } = storeToRefs(useUserStore());
const { currentDayJs, currentDayId } = storeToRefs(useDayStore());
const { activeDayId, activeDayJs, isDemo } = storeToRefs(useAppStore());

const showDateMenu = ref(false);

const dateLabel = computed(() => {
  if (currentDayId.value === activeDayId.value) {
    return "Today";
  }
  return currentDayJs.value.format("dddd, MMMM D");
});
</script>
<template>
  <div class="w-full pb-24">
    <!-- TODO - CHANGE KEY TO UPDATED PROPERTY -->
    <!-- TODO - EXPAND/COLLAPSE -->
    <div class="md:grid md:grid-cols-[auto_1fr]">
      <div class="col-span-2 flex justify-between">
        <button
          @click="showDateMenu = !showDateMenu"
          class="flex items-center text-2xl font-medium"
        >
          {{ dateLabel }}
          <Icon
            name="edit_calendar"
            class="ml-2 text-base opacity-25 transition-all hover:opacity-100"
          />
        </button>
        <router-link
          v-if="!isDemo"
          role="button"
          class="btn-outline btn-sm btn border-gray-300 font-normal normal-case text-gray-500 md:mr-2"
          to="/category/new"
        >
          New Category +
        </router-link>
      </div>
      <DateMenu v-if="showDateMenu" @changed="showDateMenu = false" />
      <div
        class="col-span-2 my-2 text-xs text-red-400"
        v-if="Math.abs(currentDayJs.diff(activeDayJs, 'day')) > 7"
      >
        HISTORICAL BLOCK VIEWING BEYOND PAST 7 DAYS COMING SOON!
      </div>
      <!-- SPACER -->
      <div class="col-span-2 my-2"></div>
      <template v-for="c in parentCategories" :key="c">
        <CategoryBlockRow :category="c" />
        <CategoryBlockRow
          v-for="s in getSubCategories(c.id)"
          :category="s"
          :key="s"
        />
      </template>
      <div v-if="parentCategories.length === 0" class="text-sm">
        Add a category to get started!
        <br />
        <br />
        Need help? Check out the
        <router-link to="/welcome" class="underline"
          >Getting Started Guide</router-link
        >.
      </div>
      <div
        v-else-if="!hasFirstBlock || isDemo"
        class="col-span-2 mt-4 border-t p-2 text-xs"
      >
        <!-- TODO - SOMETHING BETTER - TRANSITIONS OR SOMETHING -->
        <div v-if="!hasFirstBlock">
          Now that you've added a category, click on one of the boxes to record
          your first block!
        </div>
        <div v-if="isDemo">Click on the boxes to add or remove blocks!</div>
        <!-- TODO - BETTER STYLING -->
        <div class="mt-4 flex items-center justify-start gap-x-4">
          <div class="border-r py-1 pr-4">Key</div>

          <div class="flex items-center text-[0.6rem] uppercase">
            <div class="mr-1">Empty</div>
            <BaseBlock class="!h-3 !w-3" />
          </div>
          <div class="flex items-center text-[0.6rem] uppercase">
            <div class="mr-1">Quota</div>
            <BaseBlock class="!h-3 !w-3" isQuotaBlock />
          </div>
          <div class="flex items-center text-[0.6rem] uppercase">
            <div class="mr-1">Completed</div>
            <BaseBlock
              class="!h-3 !w-3"
              isQuotaBlock
              filled
              :color="BlockColor.WHITE"
            />
          </div>
        </div>
      </div>
    </div>
    <!-- TODO - MAKE THIS PROGRESSIVE ONBOARDING 
         EX) Show first message asking about ideal day, then second
             message talking about normal categories people use, asking
             if they want to see some sample allocations, etc.
    -->
  </div>
</template>

<style scoped></style>

<script setup lang="ts">
import { computed } from "vue";
import NewBlock from "@/components/NewBlock.vue";
import ExistingBlock from "@/components/ExistingBlock.vue";
import { Category } from "@/objects/category";
import { useDayStore } from "@/stores/day";
import { useAppStore } from "@/stores/app";
import { DayOfWeek } from "@/types";
import { useCategoryStore } from "@/stores/category";

const { category } = defineProps<{ category: Category }>();

// TODO - SOMETHING BETTER
const blocks = computed(() => {
  return useDayStore().getCategoryBlocks(category.id);
});

const day: DayOfWeek = useDayStore().currentDayOfWeek;
// TODO - PULL ACTIVE DAY FROM UTILS OR STORE
// OR HAVE THIS DETERMINED ON THE CATEGORY DIRECTLY?
const quota: number = category.getQuota(day);
// TODO - SHOULD THIS BE COMING FROM THE CATEGORY OBJECT DIRECTLY?
const remaining = computed(() => {
  const remaining = quota - (blocks.value?.length || 0);
  return Math.max(remaining, 0);
});

const hasParent = computed(() => {
  return !!category.parentId;
});

const subcategoryClass = "ml-3 border-l  pl-2 md:border-0 md:ml-0";
const { setActiveCategoryId } = useCategoryStore();
</script>

<template>
  <div
    class="mr-4 flex flex-col py-1 pr-4 text-sm md:items-start md:border-r"
    :class="{
      [subcategoryClass]: hasParent,
      'md:pl-4': hasParent,
      'mt-1 md:mt-0': hasParent,
    }"
    @click="setActiveCategoryId(category.id)"
  >
    <RouterLink
      :to="`/category/${category?.id}/edit`"
      class="group ml-1 flex items-center"
      :class="{
        italic: hasParent,
        'font-semibold': !hasParent,
        'pointer-events-none': useAppStore().isMobile,
      }"
    >
      {{ category?.name }}
      <Icon
        name="edit"
        class="ml-1 text-xs text-gray-400 opacity-0 transition-opacity duration-500 group-hover:opacity-100"
      />
    </RouterLink>
    <div class="ml-1 hidden text-xs text-gray-400 md:block" v-if="quota">
      {{ blocks?.length || 0 }} / {{ quota }}
    </div>
  </div>
  <div
    class="flex grow flex-wrap items-center"
    :key="blocks"
    :class="{
      [subcategoryClass]: hasParent,
      'md:pl-0': hasParent,
      'mb-1 md:mb-0': hasParent,
    }"
    @click="setActiveCategoryId(category.id)"
  >
    <ExistingBlock v-for="b in blocks" :block="b" />
    <NewBlock
      :category-id="category.id"
      :is-quota-block="remaining > 0"
      v-for="i in new Array(Math.max(remaining, 1))"
    />
  </div>
</template>

<style scoped></style>

// APP-LEVEL CONFIGURATION STORE
import { DAY_ID_FORMAT, getDayId, isMobile } from "@/util";
import dayjs from "dayjs";
import { defineStore } from "pinia";
import router from "@/router";

export const useAppStore = defineStore("app", {
  state: () => {
    return {
      loading: true,
      error: null as string | null,
      settings: {},
      navTitle: "Talloc | Time Allocated",
      initialized: false,
      time: dayjs(),
      activeDayId: getDayId(),
      isMobile: isMobile(),
    };
  },
  getters: {
    isDev(): boolean {
      return !window.location.hostname.endsWith("talloc.app");
    },
    isDemo(): boolean {
      return router.currentRoute.value.path.startsWith("/demo");
    },
    activeDayJs(): dayjs.Dayjs {
      return dayjs(this.activeDayId, DAY_ID_FORMAT);
    },
  },
  actions: {
    setLoading(loading: boolean) {
      this.loading = loading;
    },
    setError(error: string | null) {
      this.error = error;
      setTimeout(() => {
        this.error = null;
      }, 5000);
    },
    setNavTitle(title = "Talloc | Time Allocated") {
      this.navTitle = title;
    },
    refreshIsMobile() {
      this.isMobile = isMobile();
    },
    initialize() {
      if (this.initialized) {
        return console.warn("App store already initialized");
      }
      setInterval(() => {
        this.time = dayjs();

        // TODO - BETTER WAY TO DO THIS
        //      - RETRIGGER ENGINE CREATION
        // TRIGGERS RELOAD AT 4AM
        const id = getDayId(this.time);
        if (id !== this.activeDayId) {
          window.location.reload();
        }
      }, 5000);

      this.initialized = true;
    },
    // async loadSettings() {
    //   // TODO - JUST SET THIS FROM THE USER STORE?
    //   // TODO - ADD INITIALIZATION CHECK TO PREVENT DOUBLE CALLING
    //   const auth = useAuthStore();
    //   const uid = auth.uid;
    //   if (!uid) {
    //     return console.error("Unable to load settings - UID undefined...");
    //   }

    //   return new Promise((resolve, reject) => {
    //     onSnapshot(doc(firestore, `users/${uid}`), (snapshot) => {
    //       this.settings = snapshot.data()?.settings || {};
    //       resolve(true);
    //     });
    //   });
    // },
  },
});
